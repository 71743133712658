import persistence from '../utils/persistence'
const { BFF } = require('../constants')

export const fetchCertitudeLaunchURL = async (complianceSurveyOrgID, complianceSurveyMainProductID, percipioLanguageID, orgId, studentId, courseId) => {
  const jwt = JSON.parse(persistence.get('user')).jwtToken
    const params = {
      "organizationId": complianceSurveyOrgID,  
      "mainProductId": complianceSurveyMainProductID,
      "aiccSid": `P~${orgId}~${studentId}~${courseId}`,
      "languageCode": percipioLanguageID
    }
      
    const response = await fetch(`${BFF}requestcertitudetoken`, {
      body: JSON.stringify({ params }),
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${jwt}`
      },
      method: 'POST',
    })
    return response.json()
  }