import persistence from '../../utils/persistence'

const { BFF } = require('../../constants.js')

export const fetchCourseInfo = async courseId => {
  const response = await fetch(`${BFF}courseinfo/${courseId}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${JSON.parse(persistence.get('user')).jwtToken}`,
    }),
  })
  return response.json()
}
