import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import persistence from '../../utils/persistence'
import style from './Breadcrumb.module.css'

class Breadcrumb extends Component {
    static defaultProps = {
        strings: {},
    }

    constructor(props) {
        super(props)
        this.state = {
            location: [],
        }
    }

    componentDidMount() {
        this.props.history.listen(location => this.setLocation(location.pathname))
        this.setLocation(window.location.pathname)
    }

    setLocation(pathname) {
        const { updateLocation } = this.props
        let location = []
        switch (pathname) {
            case '/history':
                location.push({ url: pathname, name: 'History' })
                break
            case '/player':
                location.push({ url: pathname, name: 'Player' })
                break
            default:
                break
        }
        this.setState({
            location,
        }, () => updateLocation(pathname))
    }

    returnUrl(location) {
        const { user } = this.props
        console.log('user.compOnly value - ', user.compOnly)
        if (user.compOnly) {
            return {
                ...location,
                pathname: '/',
            }
        } else {
            console.log('persistence get value -', persistence.get('referrer'))
            let ref = persistence.get('referrer') || '/'
            console.log('ref value -', ref)
            //persistence.delete('referrer')
            return {
                ...location,
                pathname: ref,
            }
        }
    }

    render() {
        const { user, strings } = this.props
        const { location } = this.state
        const complianceString = user.compOnly ? strings.home : strings.compliance
        return (
            <div className={style.wrapper}>
                <div className={style.breadcrumb}>
                    {user.compOnly ? null : (
                        <span>
                            <a href={this.returnUrl(location).pathname}>{strings.home}</a>
                        </span>
                    )}
                    {location.length ? (
                        <span>
                            {user.compOnly ? null : (
                                <span className={style.spacer}>&gt;</span>
                            )}
                            <a href="/">{complianceString}</a>
                        </span>
                    ) : null}
                    {location.length ? location.map((loc, index) => index === location.length - 1 ? null : (
                        <span>
                            <span className={style.spacer}>&gt;</span>
                            <Link to={loc.url}>{loc.name}</Link>
                        </span>
                    )) : null}
                </div>
            </div>
        )
    }
}

export default withRouter(Breadcrumb)