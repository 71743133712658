export default (state = {}, { type, payload }) => {
    switch (type) {
        case 'COURSE_LAUNCHED':
            return {
                ...state,
                ...payload,
            }
        case 'TOGGLE_A11Y':
            return {
                ...state,
                ...payload,
            }
        default:
            return state
    }
}
