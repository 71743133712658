import React from 'react'
import moment from 'moment'
import Icon from '@skillsoft/ui/lib/Icon'
import NewWindow from '@skillsoft/ui/lib/icons/NewWindow'
import { isLaunchable, checkMobileSettings } from '../utils/isLaunchable'
import LaunchLink from '../common/launchlink/containers/LaunchLink'
import { ACADEMY } from '../constants'
import complianceIcon from '../common/img/compliance.png'
import style from './components/History.module.css'

export const openCert = (e, url) => {
  if (e !== undefined) {
    e.preventDefault()
  }
  window.open(url, '_blank', 'width=665, height=880')
}

export const getCourseImage = item => {
  if (item.courseLogoUrl.length) {
    return (
      <div className={style.contentImage}>
        <div
          style={{
            backgroundImage: `url(${item.courseLogoUrl})`,
          }}
        />
      </div>
    )
  }
  switch (item.methodAcronym) {
    // Add in cases for different icons.
    default:
      return (
        <div className={style.defaultImage}>
          <div
            style={{
              backgroundImage: `url(${complianceIcon})`,
            }}
          />
        </div>
      )
  }
}

export const getHistoryAction = (item, strings) => {
  if (item.status === 'EX') return <span>{strings.ex}</span>
  return (
    <a
      href={`${ACADEMY}${item.certificateUrl}`}
      onClick={e => openCert(e, `${ACADEMY}${item.certificateUrl}`)}
      // onKeyPress={() => openCert(`${ACADEMY}${item.certificateUrl}`)}
      aria-label={`${strings.certificate} ${item.course} ${strings.newWindow}`}
    >
      <span>{strings.certificate}</span>
      <span className={style.popupIcon}>
        <Icon icon={NewWindow} />
      </span>
    </a>
  )
}

export const formatCourseLink = (item, windowWidth) => {
  const launchable = !!(item.launch || item.launchInReview);

  const checkmobileSettings = checkMobileSettings(
    item.launch,
    item.launchInReview,
    item.supportedPlatformBitmap,
    windowWidth,
    item.langMethodAcronym === 'WBT' || item.langMethodAcronym === 'LP'
  )

  const renderTitle = () => (
    <>
      {getCourseImage(item)}
      <div
        tabIndex="0"
        aria-label={item.course}
        className={style.contentTitle}>
        {item.course}
      </div>
    </>
  )

  const renderLink = () => (
    <span className={style.contentLink}>
      <LaunchLink
        to={item.runLink}
        courseId={item.courseId}
        launchWinOptions={item.launchWinOptions}
        contentType={item.langMethodAcronym}
        historical
        launchable={launchable}
        checkMobileSettings={checkmobileSettings}
        player={item.player}
        percipioLanguageID={item.percipioLanguageID}
        complianceSurveyOrgID={item.complianceSurveyOrgID}
        complianceSurveyMainProductID={item.complianceSurveyMainProductID}
        nasbaSurveyOrgID={item.nasbaSurveyOrgID}
        nasbaSurveyMainProductID={item.nasbaSurveyMainProductID}
        hasVideo={item.hasVideo}
      >
        {renderTitle()}
      </LaunchLink>
    </span>
  )

  return (
    <div className={style.historyContent}>
      {launchable && renderLink()}
      {!launchable && renderTitle()}
    </div>
  )
}

export const formatDataForTable = (data, strings, windowWidth) =>
  data
    ? data.map(item => [
      {
        sortKey: item.course,
        content: formatCourseLink(item, windowWidth),
      },
      {
        sortKey:
          item.langMethodAcronym &&
          strings[item.langMethodAcronym.toLowerCase()],
        content:
          item.langMethodAcronym &&
          strings[item.langMethodAcronym.toLowerCase()],
      },
      {
        sortKey: item.dateCompleted,
        content: item.dateCompleted ? (
          moment(item.dateCompleted).format('MMM DD, YYYY')
        ) : (
          <div className={style.noDate}>--</div>
        ),
      },
      {
        sortKey: null,
        content: (
          <div className={style.action}>
            {getHistoryAction(item, strings)}
          </div>
        ),
      },
    ])
    : null
