import { updateIntl } from 'react-intl-redux'
import queryString from 'query-string'
import moment from 'moment'
import 'moment/min/locales.min'
import jwt_decode from 'jwt-decode'

import * as api from './api'
import * as utils from './utils'
import { getSelectedLanguage } from './selectors'
import persistence from '../utils/persistence'
import runPendo from './pendoAnalytics'

export const saveReferrerUrl = referrer => {
  if (persistence.get('referrer') !== null) return
  if (referrer === undefined || referrer === '') {
    persistence.set('referrer', 'https://www.percipio.com')
  } else {
    const referrerUrl = new URL(referrer)
    persistence.set('referrer', referrerUrl.origin)
  }
}

export const authenticateUser = async (
  getAuthenticationFn = utils.getAuthentication
) => {
  try {
    const response = await getAuthenticationFn(
      queryString.parse(window.location.search).token,
      persistence.get('user')
    )
    if (response) {
      return {
        type: 'AUTHENTICATE_USER',
        payload: response,
      }
    }
    return {
      type: 'SESSION_INVALID',
      payload: response,
    }
  } catch (err) {
    return {
      type: 'ERROR',
      payload: err,
    }
  }
}

export const fetchStringTranslations = async languageCode => {
  try {
    return updateIntl({
      locale: 'en',
      messages: await api.fetchStringTranslations(languageCode),
    })
  } catch (er) {
    try {
      return updateIntl({
        locale: 'en',
        messages: await api.fetchStringTranslations('EN-US'),
      })
    } catch (err) {
      return {
        type: 'ERROR',
        payload: err,
      }
    }
  }
}
export const fetchRootData = async (
  languageId = 1033,
  fetchRootDataFn = api.fetchRootData,
  getSelectedLanguageFn = getSelectedLanguage
) => {
  try {
    const jwt = JSON.parse(persistence.get('user')).jwtToken
    const rootData = await fetchRootDataFn({ jwt, languageId })
    if (rootData === null) {
      return { type: 'SESSION_INVALID' }
    }
    const selectedLanguage = getSelectedLanguageFn(
      rootData.languages,
      languageId
    )[0]
    moment.locale(selectedLanguage.skillportLanguageId)
    persistence.set('usePendo', rootData.user.UsePendo)
    if (rootData.user && rootData.user.UsePendo === true) {
      var decodedJwt = jwt_decode(jwt)
      import('./pendoAnalytics').then(() => runPendo('75bb7f30-5132-4ccc-6e53-006579615798')).then(() => {
        window.pendo.initialize({
          visitor: {
            id: decodedJwt.percUUID
          },
          compliance: { value: true},
          account: {
            id: decodedJwt.orgId
          }
        })
      })
    }
    
    return {
      type: 'LOAD_ROOT_DATA',
      payload: {
        ...rootData,
        selectedLanguage,
      },
    }
  } catch (err) {
    return {
      type: 'ERROR',
      payload: err,
    }
  }
}

export const courseUpdateNotification = language => ({
  type: 'COURSE_UPDATE',
  payload: { language },
})

export const fetchConfig = async (fetchConfigFn = api.fetchConfig) => {
  try {
    return {
      type: 'GET_CONFIG',
      payload: await fetchConfigFn(),
    }
  } catch (err) {
    return {
      type: 'ERROR',
      payload: err,
    }
  }
}

export const screenResize = windowWidth => ({
  type: 'SCREEN_RESIZE',
  payload: { windowWidth },
})
