import validation from '../utils/validation'

const { BFF } = require('../constants.js')

export const fetchAdminUrl = async jwt => {
  const response = await fetch(`${BFF}adminurl`, {
    headers: new Headers({
      Authorization: `Bearer ${jwt}`,
    }),
  })
  if (!validation.validate(response)) return null
  return response.json()
}
