import { connect } from 'react-redux'
import Card from '../components/Card'
import * as actions from '../actions'
import * as api from '../api'

const mapStateToProps = state => ({
  strings: {
    ...state.intl.messages.card,
    ...state.intl.messages.assignmentTypes,
    ...state.intl.messages.modal,
  },
  windowWidth: state.root.windowWidth,
})

const mapDispatchToProps = dispatch => ({
  displayCourseInfo: async (courseId, strings) => {
    let dispatchFn
    try {
      const closeModalFn = () => dispatch(actions.closeModal)
      const courseInfo = await api.fetchCourseInfo(courseId)
      const modal = await actions.displayCourseInfo(
        courseInfo,
        strings,
        closeModalFn
      )
      dispatchFn = modal
    } catch (err) {
      dispatchFn = {
        type: 'ERROR',
      }
    }
    return dispatch(dispatchFn)
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Card)
