import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SkillsoftModal from '@skillsoft/ui/lib/Modal'
import ButtonBar from '@skillsoft/ui/lib/ButtonBar'
import Button from '@skillsoft/ui/lib/Button'
import './Modal.css'

class Modal extends Component {
  static onCloseClick(updateModal) {
    updateModal({
      isOpen: false,
    })
  }

  render() {
    const { modalConfig, updateModal,isRTL } = this.props
    return (
      <SkillsoftModal
        isOpen={modalConfig.isOpen}
        contentLabel={modalConfig.content}
        onRequestClose={() => Modal.onCloseClick(updateModal)}
        title={modalConfig.title}
        contentStyle={isRTL}
      >
        <h1 className="hidden">{modalConfig.title}</h1>
        <div className="modal-content" id="fulldescription">
          {modalConfig.html ? modalConfig.html : <p>{modalConfig.content}</p>}
        </div>
        <ButtonBar className="modal-button-bar">
          <div className="modal-button">
            <Button
              className="primary-button"
              tabindex="0"
              label={modalConfig.primaryButtonLabel}
              padding
              onClick={() => modalConfig.primaryButtonClick()}
            />
          </div>
          <div
            className={
              modalConfig.secondaryButtonLabel === null ? 'hidden' : null
            }
          >
            <div className="modal-button">
              <Button
                className="secondary-button"
                tabindex="0"
                label={modalConfig.secondaryButtonLabel}
                kind="flat"
                onClick={() => modalConfig.secondaryButtonClick()}
              />
            </div>
          </div>
        </ButtonBar>
      </SkillsoftModal>
    )
  }
}

Modal.propTypes = {
  modalConfig: PropTypes.shape({
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.string,
    html: PropTypes.object,
    primaryButtonLabel: PropTypes.string,
    secondaryButtonLabel: PropTypes.string,
    primaryButtonClick: PropTypes.func,
    secondaryButtonClick: PropTypes.func,
  }),
  updateModal: PropTypes.func,
}

Modal.defaultProps = {
  modalConfig: {
    isOpen: false,
    title: '',
    content: null,
    primaryButtonLabel: null,
    secondaryButtonLabel: null,
    primaryButtonClick: () => { },
    secondaryButtonClick: () => { },
  },
  updateModal: () => { },
}

export default Modal
