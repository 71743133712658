import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '@skillsoft/ui/lib/Icon'
import PersonIcon from '@skillsoft/ui/lib/icons/Person'
import './Logo.css'
import { userNameDisplay, userOptions } from '../utils'
import NavMenu from '@skillsoft/ui/lib/NavMenu';

class UserNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  onSelectionChange(selectedUserOption){
    if (selectedUserOption.onClick !== undefined) selectedUserOption.onClick();
    if (selectedUserOption.link !== undefined) window.open(selectedUserOption.link, selectedUserOption.newWindow ? '_blank' : '_self');
  }

  render() {
    const {
      user,
      strings,
      fetchAdminUrl,
      deleteStoredValue,
      getStoredValue,
    } = this.props

    const userOptionItems = userOptions(
      user,
      strings,
      deleteStoredValue,
      getStoredValue,
      fetchAdminUrl
    );

    const getMenuOptions = userOptionItems.map(option=>{
      if(typeof(option.content) === 'object'){
        return {
        link: option.content.props.to,
        label: option.content.props.children,
        onLinkClick: () => this.onSelectionChange(option),
        }
      }
      else{
      return {
        link: '',
        label: option.content,
        onLinkClick: () => this.onSelectionChange(option),
      }
    }
    })
    
    const icon = <Icon icon={PersonIcon} />
    return (
    <div className="d-inline-block custom-dropdown">
      <NavMenu
        links={getMenuOptions}
        onClick={() => {}}
        location={window.location.pathname}
        icon={icon}
        keyName="profile_selector"><span className="dropdown-label">{userNameDisplay(user, strings)}</span>
      </NavMenu>
    </div>
    )
  }
}

UserNav.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  strings: PropTypes.objectOf(PropTypes.string),
  fetchAdminUrl: PropTypes.func,
  deleteStoredValue: PropTypes.func,
  getStoredValue: PropTypes.func,
  windowWidth: PropTypes.number,
}

UserNav.defaultProps = {
  user: {},
  strings: {},
  fetchAdminUrl: () => {},
  deleteStoredValue: () => {},
  getStoredValue: () => {},
  windowWidth: 0,
}

export default UserNav
