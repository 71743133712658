import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import BackIcon from '@skillsoft/ui/lib/icons/Back'
import NextIcon from '@skillsoft/ui/lib/icons/Next'
import setFocus from '@skillsoft/ui/lib/setFocus'
import Button from '@skillsoft/ui/lib/Button'
import whatInput from 'what-input'

import Card from '../../card/containers/Card'
import { findDOMNode } from 'react-dom'
import styles from './Carousel.css'

import Slick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class Carousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      cardIndex: 0,
      left: 0,
      originalOffset: 0,
      touchStartX: 0,
      prevTouchX: 0,
      beingTouched: false,
      disablePrevButton: true,
      disableNextButton: this.props.items.length <= 1,
      maxCards: 5,
      didSliderLoad: false
    }

    this.leftArrowClick = this.leftArrowClick.bind(this)
    this.rightArrowClick = this.rightArrowClick.bind(this)
    this.updateMaxCards = this.updateMaxCards.bind(this)
    this.handleStart = this.handleStart.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.handleEnd = this.handleEnd.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
    this.calculateHiddenButton = this.calculateHiddenButton.bind(this)

    this.getEventType = event => {
      if (event.type === 'keydown' || whatInput.ask() === 'keyboard') {
        return 'keyboard'
      }
      return 'mouse'
    }
    this.focusFrstCard = false
  }

  componentDidMount() {
    let maxCards = this.updateMaxCards()

    this.setCarouselWidth(maxCards)
  }

  async componentDidUpdate(prevProps) {
    const { windowWidth } = this.props
    if (prevProps.windowWidth !== windowWidth) {
      let maxCards = await this.updateMaxCards();
      this.setCarouselWidth(maxCards)
    }
    this.updateCards()
  }

  updateMaxCards() {
    const { windowWidth } = this.props
    let maxCards = 5
    if (windowWidth < 580) maxCards = 1
    else if (windowWidth < 820) maxCards = 2
    else if (windowWidth < 1170) maxCards = 3
    else if (windowWidth < 1400) maxCards = 4

    this.setState({
      maxCards,
    })
    return maxCards;
  }

  leftArrowClick() {
    const { selectedLanguage } = this.props
    let currentIndex = this.state.currentIndex - this.state.maxCards
    if (currentIndex < 0 && !selectedLanguage.rtl)
      currentIndex = 0
    this.setState({ currentIndex })
    this.setState({ refocusCarousel: true })
  }

  rightArrowClick() {
    const { selectedLanguage, items } = this.props
    if (items.length - this.state.currentIndex > this.state.maxCards) {
      let currentIndex = this.state.currentIndex + this.state.maxCards
      if (currentIndex < items.length && selectedLanguage.rtl) currentIndex = 0
      this.setState({ currentIndex })
    }
    this.setState({ refocusCarousel: true })
  }

  handleStart(clientX) {
    this.setState({
      originalOffset: this.state.left,
      touchStartX: clientX,
      beingTouched: true,
    })
  }

  handleMove(clientX) {
    if (this.state.beingTouched) {
      const touchX = clientX
      const deltaX = touchX - this.state.touchStartX + this.state.originalOffset
      this.setState({
        left: deltaX,
        prevTouchX: touchX,
      })
    }
  }

  handleEnd() {
    if (this.state.left < -150) {
      this.rightArrowClick()
    } else if (this.state.left > 150) {
      this.leftArrowClick()
    }
    this.setState({
      left: 0,
      touchStartX: 0,
      beingTouched: false,
    })
  }

  handleTouchStart(touchStartEvent) {
    // touchStartEvent.preventDefault()
    this.handleStart(touchStartEvent.targetTouches[0].clientX)
  }

  handleTouchMove(touchMoveEvent) {
    this.handleMove(touchMoveEvent.targetTouches[0].clientX)
  }

  handleTouchEnd(touchEndEvent) {
    const swipeDetected = this.handleEnd()
    if (swipeDetected) touchEndEvent.preventDefault()
  }

  calculateHiddenButton(items, rtl, side) {
    if (side === 'left') {
      return rtl
        ? items.length + this.state.currentIndex <= this.state.maxCards
        : this.state.currentIndex === 0
    }
    return rtl
      ? this.state.currentIndex === 0
      : items.length - this.state.currentIndex <= this.state.maxCards
  }

  disableButtons() {
    const { cardIndex, maxCards } = this.state
    const { items } = this.props
    if (cardIndex !== null) {
      const disablePrevButton = !cardIndex
      const disableNextButton = items.length - cardIndex <= maxCards
      if (
        disablePrevButton !== this.state.disablePrevButton ||
        disableNextButton !== this.state.disableNextButton
      ) {

        this.setState({
          disablePrevButton,
          disableNextButton,
        })
      }
    }
  }

  focusFirstCard() {
    const { focusFrstCard } = this
    // eslint-disable-next-line react/no-find-dom-node
    const firstActiveCard = findDOMNode(this.slider)
      ?.querySelector('div.slick-slide.slick-active')
      ?.querySelector('div.card-content-title')
    if (focusFrstCard) {
      setFocus(firstActiveCard)
      this.focusFrstCard = false
    }
  }

  updateCards = () => {
    let firstInCarousel = true
    const { didSliderLoad, maxCards } = this.state
    // Replace this with ref
    // eslint-disable-next-line react/no-find-dom-node
    const carousel = findDOMNode(this.slider)

    if (carousel && !didSliderLoad) {
      this.setCarouselWidth(maxCards)
      this.setState({ didSliderLoad: true })
    }
    const cards = carousel?.querySelectorAll('div.slick-slide')
    if (!cards) {
      return
    }
    // convert to array for IE
    // eslint-disable-next-line react/no-find-dom-node
    const cardElements = [...cards]
    cardElements.forEach(card => {
      const isActive = card.getAttribute('class').includes('slick-active')
      if (isActive && firstInCarousel) {
        card.setAttribute('data-marker', 'firstCard')
        firstInCarousel = false
      }
      card.removeAttribute('tabindex')
      const interactiveElements = card?.querySelectorAll('div.card-content-title')
      if (interactiveElements) {
        const els = [...interactiveElements]
        els.forEach(element => {
          element.setAttribute('tabindex', isActive ? '0' : '-1')
        })
      }
    })
  }
  setCarouselWidth(slidesToShow) {
    const cardMargin = 24;
    // eslint-disable-next-line react/no-find-dom-node
    const slider = findDOMNode(this.slider)
    const width = (slidesToShow * 240) - cardMargin
    // eslint-disable-next-line no-unused-expressions
    slider?.parentNode.setAttribute(
      'style',
      `width: ${width + cardMargin}px;`
    )

  }

  slideChange = index => {
    this.setState(
      {
        cardIndex: index,
      },
      () => {
        this.disableButtons()
        this.updateCards();
        this.focusFirstCard()
      }
    )
  }
  prevSlide = event => {
    this.slider.slickPrev()
    this.eventType = this.getEventType(event)
    this.focusFrstCard = true
  }

  nextSlide = event => {
    this.slider.slickNext()
    this.eventType = this.getEventType(event)
    this.focusFrstCard = true
  }
  keystrokeHandler = event => {
    const { disablePrevButton, disableNextButton } = this.state
    const actions = {
      ArrowRight: () => {
        event.preventDefault()
        if (!disableNextButton) {
          this.nextSlide(event)
        }
      },
      ArrowLeft: () => {
        event.preventDefault()
        if (!disablePrevButton) {
          this.prevSlide(event)
        }
      },
    }
    if (actions[event.key]) {
      actions[event.key]()
    }
  }
  render() {
    const { items, id } = this.props
    const { cardIndex, remountIndex, maxCards } = this.state;
    const { disableNextButton, disablePrevButton } = this.state

    const slickKey = `${id}${remountIndex}`
    const modifiedItems = items.map((item, index) => {

      return <div key={index}><Card
        content={item}
        tabbable={
          index >= cardIndex &&
          index < cardIndex + this.state.maxCards
        }
        overdue={item.status === 'OD'}
      /></div>
    })

    return (
      <div className={'container'} onKeyDown={this.keystrokeHandler}>
        <div className={'slickWrapper'}>
          {
            (!disablePrevButton && (
              <div
                className={'buttonContainer leftButton'}>
                <div
                  className={classNames(styles.button, {
                    [styles.hidden]: disablePrevButton,
                  })}
                >
                  <Button
                    size="large"
                    kind="flat"
                    onClick={this.prevSlide}
                    icon={BackIcon}
                    role="button"
                    label={'Previous Group'}
                    showLabel={false}
                  />
                </div>
              </div>
            ))}
          <Slick
            ref={slider => {
              this.slider = slider
            }}
            key={slickKey}
            afterChange={this.slideChange}
            dots={false}
            arrows={false}
            infinite={false}
            slidesToShow={maxCards}
            slidesToScroll={maxCards}
            swipe
            variableWidth
            accessibility={false}
            useCSS
            className={styles.carousel}
            lazyLoad
          >
            {modifiedItems}
          </Slick>

          {
            (!disableNextButton && (
              <div
                className={'buttonContainer rightButton'}>
                <div
                  className={classNames(styles.button, {
                    [styles.hidden]: disableNextButton,
                  })}
                >
                  <Button
                    size="large"
                    kind="flat"
                    onClick={this.nextSlide}
                    icon={NextIcon}
                    role="button"
                    label={"Next Group"}
                    showLabel={false}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  strings: PropTypes.objectOf(PropTypes.string),
  selectedLanguage: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  windowWidth: PropTypes.number,
}

Carousel.defaultProps = {
  items: null,
  strings: {},
  selectedLanguage: {
    language: 'English',
    languageLong: 'English (United States)',
    languageKey: 'EN-US',
    languageId: 1033,
    rtl: false,
  },
  windowWidth: null,
}

export default Carousel
