import React from 'react'
import v4 from 'uuid'
import Icon from '@skillsoft/ui/lib/Icon'
import SortedDownIcon from '@skillsoft/ui/lib/icons/SortedDown'
import SortedUpIcon from '@skillsoft/ui/lib/icons/SortedUp'
import { primarySort } from './utils'

export const getMobileFilterList = (
  headers,
  sortFunction,
  sortColumn,
  reverseSort,
  primarySortFn = primarySort
) => {
  let options = headers.map((th, index) => ({
    id: v4(),
    content: (
      <span className="table-mobile-filter-item">
        <span>
          {index === sortColumn && (
            <Icon icon={reverseSort ? SortedDownIcon : SortedUpIcon} />
          )}
        </span>
        <span>{th.label}</span>
      </span>
    ),
    onClick: () => sortFunction(index),
    newWindow: false,
    sortable: th.sortable,
    sortKey: th.label,
  }))
  options = options.filter(option => option.sortable && option)
  options = options.sort((a, b) => primarySortFn(a, b))
  return options
}
