const config = require('./config.json')

// if (process.env.NODE_ENV === undefined) {
//   config.data = {
//     ...config,
//     // additional settings may go here if they are not environment specific
//   }
// } else {
//   config.data = {
//     ...config,
//   }
// }

// this is set up specifically this way to function as a template for settings
module.exports = {
  BFF: config.BFF,
  ACADEMY: config.ACADEMY,
  BFFSOCKET: config.BFFSOCKET,
  ENABLEFILELOGGING: config.ENABLEFILELOGGING,
  ENABLEDATABASELOGGING: config.ENABLEDATABASELOGGING,
  LOGDIR: config.LOGDIR,
  ROOTLOGFILENAME: config.ROOTLOGFILENAME,
  AUTOREFRESHENABLED: config.AUTOREFRESHENABLED,
  AUTHENTICATIONFAILUREURL: config.AUTHENTICATIONFAILUREURL,
  KEYS: {
    TAB: 9,
    RETURN: 13,
    ESC: 27,
    SPACE: 32,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
  },
  COURSELISTINFOFPAGE: 'NewSkillsoftCourses.aspx',
  HELPURL: config.HELPURL,
}
