export default (state = {}, { type, payload }) => {
  switch (type) {
    case 'UPDATE_MODAL':
      return {
        ...state,
        modalConfig: {
          ...state.modalConfig,
          ...payload,
        },
      }
    default:
      return state
  }
}
