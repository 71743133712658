import { connect } from 'react-redux'
import Modal from '../components/Modal'

const mapStateToProps = state => ({
  modalConfig: state.modal.modalConfig,
})

const mapDispatchToProps = dispatch => ({
  updateModal(modalConfig) {
    dispatch({
      type: 'UPDATE_MODAL',
      payload: modalConfig,
    })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal)
