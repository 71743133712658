import { connect } from 'react-redux'
import ToastNotification from '../components/ToastNotification'

const mapStateToProps = state => ({
  displayToast: state.toastNotification.displayToast,
  toastType: state.toastNotification.toastType,
  strings: state.intl.messages.toast,
  toastMessage: state.toastNotification.toastMessage,
  to: state.toastNotification.to,
  toTarget: state.toastNotification.toTarget,
  toastActionText: state.toastNotification.toastActionText,
  selectedLanguage: state.root.selectedLanguage,
})

const mapDispatchToProps = dispatch => ({
  dismissToast() {
    return dispatch({ type: 'DISMISS_TOAST' })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastNotification)
