export default (state = {}, { type }) => {
  switch (type) {
    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        searchCleared: true,
      }
    case 'SEARCH_CLEARED':
      return {
        ...state,
        searchCleared: false,
      }
    default:
      return state
  }
}
