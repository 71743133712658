import { connect } from 'react-redux'
import Carousel from '../components/Carousel'

const mapStateToProps = state => ({
  strings: state.intl.messages.carousel,
  selectedLanguage: state.root.selectedLanguage,
  windowWidth: state.root.windowWidth,
})

export default connect(mapStateToProps)(Carousel)
