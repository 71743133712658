import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import './Table.css'
import { tableBodyShape } from '../shapes'

const TableBody = ({ sortedData, strings, columnLength }) => (
  <tbody>
    {sortedData.length ? (
      sortedData.map(tr => (
        <tr key={v4()}>
          {tr.map((td, index) =>
            index === 0 ? (
              <th scope="row" className="table-cell" key={v4()}>
                <div className="table-cell-back">{td.content}</div>
              </th>
            ) : (
              <td className="table-cell" key={v4()}>
                <div className="table-cell-back">{td.content}</div>
              </td>
            )
          )}
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={columnLength}>
          <div className="table-no-results">{strings.noResultsFound}</div>
        </td>
      </tr>
    )}
  </tbody>
)

TableBody.propTypes = PropTypes.shape(tableBodyShape).isRequired

TableBody.defaultProps = {
  sortedData: [],
  strings: {},
  columnLength: 0,
}
export default TableBody
