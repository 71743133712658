import { KEYS } from '../../constants'

export const handleKeyDown = (event, callbackFn) => {
  switch (event.which) {
    case KEYS.RETURN:
    case KEYS.SPACE:
      event.preventDefault()
      callbackFn()
      break
    default:
  }
}
