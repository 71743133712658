// IE11 doesn't support endsWith, so this function handles it.
export const endsWith = (str, suffix) =>
  str.indexOf(suffix, str.length - suffix.length) !== -1

// Renders the contents of a <style> object with all Skillsoft CSS variables.
export const renderStyleVariables = variables => {
  const styles = Object.keys(variables).reduce(
    (acc, key) =>
      endsWith(key, 'Url')
        ? `${acc} --${key}: url('${variables[key]}');\n
       --${key.replace('Url', '')}: ${variables[key]};\n`
        : `${acc} --${key}: ${variables[key]};\n`,
    ''
  )
  return `:root {${styles}}`
}
