export const ACTIONS = {
    OPEN_FEEDBACK_MODAL: "OPEN_FEEDBACK_MODAL",
    CLOSE_FEEDBACK_MODAL: "CLOSE_FEEDBACK_MODAL"
}

export const openModal = (launchUrl) => ({
    type: ACTIONS.OPEN_FEEDBACK_MODAL,
    payload: {
        launchUrl
    }
})

export const closeModal = () => ({
    type: ACTIONS.CLOSE_FEEDBACK_MODAL
})

export default {
    openModal,
    closeModal
}