export const clearSearch = () => ({ type: 'SEARCH_CLEARED' })

export const search = (term, searchField, searchData, dispatchType) => ({
  type: dispatchType,
  payload: {
    searchResults: searchData.filter(
      item => item[searchField].toLowerCase().indexOf(term) !== -1
    ),
  },
})
