export const courseReplacementList = courses =>
  courses.filter(course => (course.courseReplacement ? course : null))

export const getSelectedLanguage = (languages, selectedLanguageId) => {
  let language = languages.filter(lang =>
    lang.languageId === selectedLanguageId ? lang : null
  )
  if (!language[0])
    language = languages.filter(lang =>
      lang.languageId === 1033 ? lang : null
    )
  return language
}
