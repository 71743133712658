const validation = {
  validate: response => {
    if (response.status === 401) {
      return false
    }
    return true
  },
}

export default validation
