import React, { useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import Icon from '@skillsoft/ui/lib/Icon'
import Info from '@skillsoft/ui/lib/icons/Info'
import ToolTip from '@skillsoft/ui/lib/Tooltip'
import { cardShape } from '../shapes'
import { handleKeyDown, ignoreSpace } from '../utils'
import { isLaunchable, checkMobileSettings } from '../../../utils/isLaunchable'
import LaunchLink from '../../launchlink/containers/LaunchLink'
import complianceIcon from '../../img/compliance.png'
import isMobile from '../../../utils/isMobile'
import './Card.css'

const Card = ({
  content,
  overdue,
  strings,
  windowWidth,
  tabbable,
  displayCourseInfo,
  assignmentTypesInfo
}) => {
  const courseTypeDivRef = useRef();
  const dueDivRef = useRef();
  const detailsDivRef = useRef();
  const dateDivRef = useRef();
  const {
    runLink,
    courseId,
    launchWinOptions,
    langMethodAcronym,
    confirmCompletion,
    course,
    priorCourseTitle,
    launch,
    launchInReview,
    courseLogoUrl,
    dueDate,
    supportedPlatformBitmap,
    player,
    percipioLanguageID,
    complianceSurveyOrgID,
    complianceSurveyMainProductID,
    nasbaSurveyOrgID,
    nasbaSurveyMainProductID,
    hasVideo
  } = content
  const launchable = isLaunchable(
    launch,
    launchInReview,
    supportedPlatformBitmap,
    windowWidth,
    langMethodAcronym === 'WBT' || langMethodAcronym === 'LP'
  )

  const checkmobileSettings = checkMobileSettings(
    launch,
    launchInReview,
    supportedPlatformBitmap,
    windowWidth,
    langMethodAcronym === 'WBT' || langMethodAcronym === 'LP'
  )

  const processDueDate = (dueDate) => {
    dueDate = moment(dueDate).format('MMMM DD, YYYY')
    return (dueDate !== null && overdue && strings.pastDue) ? (`${strings.pastDue} ${dueDate}`) : (`${strings.due} ${dueDate}`)
  }

  return (
    <div className="card-root">
      <LaunchLink
        to={runLink}
        courseId={courseId}
        launchWinOptions={launchWinOptions}
        contentType={langMethodAcronym}
        confirmCompletion={confirmCompletion}
        title={course}
        priorCourseTitle={priorCourseTitle}
        launchable={launchable}
        isMobile={isMobile(windowWidth)}
        checkMobileSettings={checkmobileSettings}
        tabIndex={tabbable ? '0' : '-1'}
        ariaLabel={course}
        player={player}
        percipioLanguageID={percipioLanguageID}
        complianceSurveyOrgID={complianceSurveyOrgID}
        complianceSurveyMainProductID={complianceSurveyMainProductID}
        nasbaSurveyOrgID={nasbaSurveyOrgID}
        nasbaSurveyMainProductID={nasbaSurveyMainProductID}
        hasVideo={hasVideo}
      >
        <div className="card-content">
          <div tabIndex="-1" className="card-image-back" aria-hidden="true">
            {courseLogoUrl ? (
              <div
                alt=""
                aria-hidden="true"
                className="card-image"
                tabIndex="-1"
                style={{
                  backgroundImage: `url(${courseLogoUrl})`,
                  backgroundSize: 'cover',
                }}
              />
            ) : (
              <div
                alt=""
                aria-hidden="true"
                className="card-image"
                tabIndex="-1"
                style={{ backgroundImage: `url(${complianceIcon})` }}
              />
            )}
          
          </div>
         
            {
              (langMethodAcronym === 'LP' || langMethodAcronym === 'LNK') ? ( <div className='visually-hidden' tabIndex="0" ><span>{strings.opensInNewTab}</span></div>) : ''
            }
          
          <h3 className="card-content-header">
            <div
              role="link"
              tabIndex={tabbable ? '0' : '-1'}
              className="card-content-title"
              title={course}
              style={{
                WebkitBoxOrient: 'vertical',
              }}
            >
              {course}
            </div>
          </h3>
          <div tabIndex="-1" className="card-content-type" >
            <div tabIndex="-1"
              aria-label={langMethodAcronym && strings[langMethodAcronym.toLowerCase()]}
              ref={courseTypeDivRef}
              >
              {langMethodAcronym && strings[langMethodAcronym.toLowerCase()]}
            </div>
            <div />
          </div>
          {overdue && (
              <div className="card-past-due"
                tabIndex="-1"
                aria-label={strings.pastDue}
                ref={dueDivRef}
              >
                {strings.pastDue}
              </div>
            )}
          {isMobile(windowWidth) && launchable && supportedPlatformBitmap !== 15 ? (
            <div
              tabIndex="-1"
              className="card-content-subtext"
              aria-hidden="true"
            >
              {strings.desktopOrLaptopOnly}
            </div>
          ) : null}
          {dueDate && (
            <div
              tabIndex="-1"
              className={classNames({
                'card-content-due-date': true,
                'card-overdue': overdue,
              })}
              aria-label={processDueDate(dueDate)}
              ref={dateDivRef}              
            >
              <span>
                {strings.due}: {moment(dueDate).format('MMM DD, YYYY')}
              </span>
            </div>
          )}
        </div>
      </LaunchLink>
      <div
        className='card-info'
        ref={detailsDivRef}
        tabIndex={tabbable ? '0' : '-1'}
        onClick={() => {
          document.activeElement.blur()
          return displayCourseInfo(courseId, strings)
        }}
        onKeyPress={e =>
          handleKeyDown(e, () => displayCourseInfo(courseId, strings))
        }
        aria-label={`${assignmentTypesInfo} ${langMethodAcronym && strings[langMethodAcronym.toLowerCase()]}`}
        role="button"
      >
        <ToolTip
          overlay={<span>{strings.courseDetails}</span>}
          tooltipProps={{
            align: { offset: [0, -8] },
            placement: 'top',
          }}
        >
          <Icon className="card-info-icon" icon={Info} />
        </ToolTip>
      </div>
    </div>
  )
}

Card.propTypes = PropTypes.shape(cardShape).isRequired

Card.defaultProps = {
  content: null,
  overdue: null,
  strings: {},
  windowWidth: null,
  tabbable: false,
  assignmentTypesInfo: null
}

export default Card
