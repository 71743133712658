import { connect } from 'react-redux'
import Root from '../components/Root'
import * as actions from '../actions'
import * as selectors from '../selectors'

const mapStateToProps = state => ({
  loading: state.root.loading,
  returnUrl: state.root.returnUrl,
  assignments: state.root.assignments,
  selectedLanguage: state.root.selectedLanguage,
})

const mapDispatchToProps = dispatch => ({
  saveReferrerUrl: referrer => {
    actions.saveReferrerUrl(referrer)
  },
  authenticateUser: async () => {
    const authorized = await actions.authenticateUser()
    dispatch(authorized)
    return authorized.payload.userInfo
  },
  fetchStringTranslations: async languageCode => {
    const translationStrings = await actions.fetchStringTranslations(
      languageCode
    )
    dispatch(translationStrings)
  },
  fetchRootData: async languageId => {
    const rootData = await actions.fetchRootData(languageId)
    dispatch(rootData)
  },
  fetchConfig: async () => {
    const configData = await actions.fetchConfig()
    dispatch(configData)
  },
  courseUpdateNotification: (assignments, selectedLanguage) => {
    if (selectors.courseReplacementList(assignments).length)
      dispatch(actions.courseUpdateNotification(selectedLanguage))
  },
  screenResize: windowWidth => {
    dispatch(actions.screenResize(windowWidth))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root)
