// Check if user's device is mobile.  If it is mobile, check if it has a
// large enough screen to display the desktop version.
const isMobile = windowWidth => {
  let mobile = false
  if (/Mobi|Android/i.test(navigator.userAgent)) {
    mobile = true
  }
  if (windowWidth > 720) mobile = false
  return mobile
}

export default isMobile
