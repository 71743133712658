import React from 'react'
import { assignmentsShape } from '../shapes'
import Carousel from '../../common/carousel/containers/Carousel'
import Card from '../../common/card/containers/Card'
import isMobile from '../../utils/isMobile'
import style from './Assignments.module.css'
import { Link, withRouter } from 'react-router-dom'

const Assignments = ({ strings, required, optional, windowWidth }) => {
  const requiredHeader = required.length
    ? `${strings.requiredComplianceAssignments} (${required.length})`
    : !optional.length ? 
    <div className={style.completedDivWrapper}>
      <div style={{ paddingTop: "5px" }}>
        <h2> {strings.completedAllAssignments} </h2>
      </div>
      <div className={style.reviewCompleted}>
        {strings.reviewCompletedAssignments}<Link to="/history" className={style.reviewCompletedLink}>{strings.reviewCompletedLink}</Link>
      </div>
    </div>
      : null

  return (
    <div id="main" tabIndex="-1" className={style.root}>
      <div className={style.assignments}>
        <div className={style.header}>
          <div className={style.headerLeft}>
            <h2>{requiredHeader}</h2>
          </div>
          {required.length ? (
            <div>
              {isMobile(windowWidth) ? (
                <Carousel items={required} />
              ) : (
                <div className={style.list}>
                  {required.map((item, index) =>
                    <div key={`required-${index}`} className={style.card}>
                      <Card
                        content={item}
                        tabbable
                        overdue={item.status === 'OD'}
                        assignmentTypesInfo={strings.assignmentTypesInformation}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {optional.length ? (
            <div>
              <div className={style.headerLeft}>
                <h2>{strings.optionalComplianceAssignments} ({optional.length})</h2>
              </div>
              {isMobile(windowWidth) ? (
                <Carousel items={optional} />
              ) : (
                <div className={style.list}>
                  {optional.map((item, index) =>
                    <div key={`optional-${index}`} className={style.card}>
                      <Card
                        content={item}
                        tabbable
                        assignmentTypesInfo={strings.assignmentTypesInformation}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}

        </div>
      </div>
    </div>
  )
}

Assignments.propTypes = assignmentsShape

Assignments.defaultProps = {
  strings: {},
  required: [],
  optional: [],
  windowWidth: null,
}

export default Assignments
