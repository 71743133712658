import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableHeaders from './TableHeaders'
import TableBody from './TableBody'
import TableBodyMobile from './TableBodyMobile'
import TableTitle from './TableTitle'
import './Table.css'
import {
  announceSortOrder,
  getSortPriority,
  reverseSortedData,
  sortData,
} from '../utils'
import { tableShape } from '../shapes'

class Table extends Component {
  static propTypes = PropTypes.shape(tableShape).isRequired
  static defaultProps = {
    defaultSort: 0,
    headers: null,
    data: null,
    sortPriority: null,
    strings: {},
    title: null,
    defaultData: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      sortColumn: null,
      reverseSort: true,
      uiSize: null,
      mobileSearch: false,
      userSorted: false,
    }
    this.sortFunction = this.sortFunction.bind(this)
    this.toggleMobileSearch = this.toggleMobileSearch.bind(this)
  }

  componentWillMount() {
    const { defaultSort } = this.props
    this.updateUiSize()

    this.sortFunction(defaultSort)
    const sortColumn = document.getElementById(this.state.sortColumn)
    if (sortColumn != null) sortColumn.blur()
  }

  componentDidUpdate(prevProps) {
    const { data, windowWidth } = this.props
    if (windowWidth !== prevProps.windowWidth) this.updateUiSize()
    if (data !== prevProps.data) {
      this.sortFunction(this.state.sortColumn, true)
    }

    if (this.state.userSorted) {
      document.getElementById(this.state.sortColumn).focus()
    }
  }

  updateUiSize() {
    const { windowWidth } = this.props
    let uiSize = 'desktop'
    if (windowWidth < 721) uiSize = 'mobile'
    this.setState({ uiSize })
  }

  toggleMobileSearch() {
    this.setState({
      mobileSearch: !this.state.mobileSearch,
    })
  }

  sortFunction(
    columnIndex,
    skipReverse = false,
    userSorted = false,
    getSortPriorityFn = getSortPriority,
    reverseSortedDataFn = reverseSortedData,
    sortDataFn = sortData
  ) {
    if (userSorted)
      this.setState({
        userSorted: true,
      })
    else
      this.setState({
        userSorted: false,
      })

    const { data, sortPriority } = this.props
    const priority = getSortPriorityFn(columnIndex, sortPriority)
    let reverse = this.state.reverseSort
    if (!skipReverse) {
      reverse = reverseSortedDataFn(
        columnIndex,
        this.state.sortColumn,
        this.state.reverseSort
      )
    }
    this.setState({
      sortColumn: columnIndex,
      sortedData: sortDataFn(data, priority, reverse),
      reverseSort: reverse,
    })
  }

  render() {
    const {
      headers,
      data,
      sortPriority,
      strings,
      defaultData,
      title,
    } = this.props
    const {
      uiSize,
      mobileSearch,
      sortColumn,
      reverseSort,
      sortedData,
    } = this.state
    return this.state.sortedData ? (
      <div className="table-root">
        <div
          aria-live="polite"
          aria-label={
            strings.resultsFound &&
            strings.resultsFound.replace(
              /{numberResults}/g,
              this.state.sortedData.length
            )
          }
        />
        <span id="sortableannouncement" className="table-hidden">
          {strings.sortDescribedBy  || "sortable ascending or decending order"}
        </span>
        <TableTitle
          title={title}
          searchData={defaultData}
          uiSize={uiSize}
          mobileSearch={mobileSearch}
          toggleMobileSearch={this.toggleMobileSearch}
          sortFunction={this.sortFunction}
          headers={headers}
          sortColumn={sortColumn}
          reverseSort={reverseSort}
          strings={strings}
        />
        {uiSize === 'mobile' ? (
          <table className="table-mobile">
            <caption className="table-hidden">Compliance History</caption>
            <TableBodyMobile
              sortedData={this.state.sortedData}
              strings={strings}
              columnLength={headers.length}
            />
          </table>
        ) : (
          <table>
            <caption className="table-hidden">Compliance History</caption>
            <TableHeaders
              headers={headers}
              sortColumn={sortColumn}
              reverseSort={reverseSort}
              sortPriority={sortPriority}
              data={data}
              sortFunction={this.sortFunction}
              announceSortOrder={announceSortOrder}
            />
            <TableBody
              sortedData={sortedData}
              strings={strings}
              columnLength={headers.length}
            />
          </table>
        )}
      </div>
    ) : null
  }
}

export default Table
