import React from 'react'
import PropTypes from 'prop-types'
import Toast from '@skillsoft/ui/lib/Toast'
import './ToastNotification.css'

const ToastNotification = ({
  displayToast,
  dismissToast,
  strings,
  toastType,
  toastMessage,
  to,
  toTarget,
  toastActionText,
  selectedLanguage,
}) =>
  displayToast ? (
    <div className="toast-root">
      <Toast
        type={toastType}
        shadow
        animate={selectedLanguage.rtl ? 'from-left' : 'from-right'}
        onRemove={dismissToast}
      >
        {strings[toastMessage]}
        {to ? (
          <a className="toast-action" href={to} target={toTarget}>
            {strings[toastActionText]}
          </a>
        ) : null}
      </Toast>
    </div>
  ) : null

ToastNotification.propTypes = {
  displayToast: PropTypes.bool,
  dismissToast: PropTypes.func,
  strings: PropTypes.objectOf(PropTypes.string),
  toastType: PropTypes.string,
  toastMessage: PropTypes.string,
  to: PropTypes.string,
  toTarget: PropTypes.string,
  toastActionText: PropTypes.string,
  selectedLanguage: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
}

ToastNotification.defaultProps = {
  displayToast: false,
  dismissToast: () => {},
  strings: {},
  toastType: null,
  toastMessage: null,
  to: null,
  toTarget: null,
  toastActionText: null,
  selectedLanguage: {
    language: 'English',
    languageLong: 'English (United States)',
    languageKey: 'EN-US',
    languageId: 1033,
    rtl: false,
  },
}

export default ToastNotification
