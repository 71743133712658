import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import actions from '../actions';
import Component from '../components/FeedbackModal'
import { useHistory } from 'react-router-dom'

export default function Container(props) {
    const dispatch = useDispatch();

    const componentProps = {
        isOpen: useSelector(state => state.feedbackModal.isOpen),
        intl: {
            close: useSelector(state => state.intl.messages.modal.close),
            ...useSelector(state => state.intl.messages.feedbackModal)
        },
        launchUrl: useSelector(state => state.feedbackModal.launchUrl),
        onClose: () => {
            dispatch(actions.closeModal())
        }
    }

    const history = useHistory();

    useEffect(() => {
        // console.log("Init FeedbackModal")

        // Detects navigate back button clicked in browser and closes feedback modal if open  
            return () => {
                if (history.action === "POP") {
                    dispatch(actions.closeModal())
                }
            }
    }, [])

    return (
        <Component {...componentProps} />
    )
}