import React from "react"

export const launchCourse = async (
  launchParams,
  launchUrl
) => {
  const { courseId } = launchParams
  if (launchUrl.url === null) {
    return { type: 'SESSION_INVALID' }
  }
  if (launchUrl.url === 'err') {
    return {
      type: 'ERROR',
      payload: launchUrl.err,
    }
  }
  return {
    type: 'COURSE_LAUNCHED',
    payload: { courseId },
  }
}

export const toggleA11y = toggle => ({
  type: 'TOGGLE_A11Y',
  payload: { a11yEnabled: toggle },
})