import PropTypes from 'prop-types'

const tDataShape = PropTypes.arrayOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      sortKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  )
)

const theadShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    sortable: PropTypes.bool,
  })
)

export const tableShape = {
  defaultSort: PropTypes.number,
  headers: PropTypes.shape(theadShape),
  data: PropTypes.shape(tDataShape),
  sortPriority: PropTypes.arrayOf(PropTypes.number),
  strings: PropTypes.objectOf(PropTypes.string),
  windowWidth: PropTypes.number,
  title: PropTypes.string,
  defaultData: PropTypes.shape(tDataShape),
}

export const tableBodyShape = {
  sortedData: PropTypes.shape(tDataShape),
  strings: PropTypes.objectOf(PropTypes.string),
  columnLength: PropTypes.number,
}

export const tableHeadersShape = {
  sortFunction: PropTypes.func,
  announceSortOrder: PropTypes.func,
  headers: PropTypes.shape(theadShape),
  sortColumn: PropTypes.number,
  reverseSort: PropTypes.bool,
}

export const tableTitleShape = {
  strings: PropTypes.objectOf(PropTypes.string),
  searchData: PropTypes.arrayOf(PropTypes.object),
  uiSize: PropTypes.string,
  mobileSearch: PropTypes.bool,
  toggleMobileSearch: PropTypes.func,
  sortFunction: PropTypes.func,
  headers: PropTypes.shape(theadShape),
}
