import { connect } from 'react-redux'
import UserNav from '../components/UserNav'
import persistence from '../../utils/persistence'

import * as api from '../api'

const mapStateToProps = state => ({
  strings: Object.assign(
    {},
    state.intl.messages.header,
    state.intl.messages.global
  ),
  user: state.header.user,
  windowWidth: state.root.windowWidth,
})

const mapDispatchToProps = dispatch => ({
  async fetchAdminUrl() {
    try {
      const adminUrlResponse = await api.fetchAdminUrl(
        JSON.parse(localStorage.getItem('user')).jwtToken
      )
      if (adminUrlResponse === null) {
        dispatch({ type: 'SESSION_INVALID' })
        return
      }
      window.location = adminUrlResponse.launchURL
      dispatch({ type: 'FETCH_ADMIN_URL' })
    } catch (err) {
      dispatch({
        type: 'ERROR',
        payload: err,
      })
    }
  },
  deleteStoredValue(key) {
    persistence.delete(key)
  },
  getStoredValue(key) {
    return persistence.get(key)
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNav)
