import { connect } from 'react-redux'
import variables from '@skillsoft/ui/themes/variables'
import StyleProcessor from '../components/StyleProcessor'
import { renderStyleVariables } from '../utils'

const mapStateToProps = () => ({
  styles: renderStyleVariables(variables),
})

export default connect(mapStateToProps)(StyleProcessor)
