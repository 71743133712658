export const filterAssignments = (assignments, reqOpt) =>
  assignments
    ? assignments.filter(
        assignment =>
          assignment.reqOpt === reqOpt &&
          (assignment.status !== 'C' &&
            assignment.status !== 'EQ' &&
            assignment.status !== 'EX')
      )
    : []
