import { connect } from 'react-redux'
import Table from '../components/Table'

const mapStateToProps = state => ({
  strings: {
    ...state.intl.messages.table,
    ...state.intl.messages.history,
  },
  windowWidth: state.root.windowWidth,
})

export default connect(mapStateToProps)(Table)
