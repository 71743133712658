import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GlobeIcon from '@skillsoft/ui/lib/icons/Language'
import Icon from '@skillsoft/ui/lib/Icon'
import NavMenu from '@skillsoft/ui/lib/NavMenu'
import isMobile from '../../utils/isMobile'
import style from './Header.module.css'

class LanguageSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      selectedLanguage: '',
      disabled: false,
      location: '/',
    }
  }

  componentDidMount() {
    const selectedLanguage = this.props.selectedLanguage?.language;
    this.setState({ selectedLanguage })
  }

  componentDidUpdate() {
    const { location } = this.props
    if (location !== this.state.location) {
      this.setState({
        location,
      })
    }
  }

  onSelectionChange(selectedValue) {
    this.props.changeLanguage(selectedValue);
    this.setState({
      selectedLanguage: selectedValue.language
    })
  }

  render() {
    const { allowedLanguages } = this.props
    const { location } = this.state
    const { selectedLanguage } = this.state;
    const getMenuOptions = allowedLanguages.map(lang => {
      return {
        link: '',
        label: lang.language,
        lang: lang.languageId,
        onLinkClick: () => this.onSelectionChange(lang),
      }
    })

    const icon = <Icon icon={GlobeIcon} />
    return location === '/player' ? (
      <div className="d-inline-block custom-dropdown">
        <div className={style.disabledDropdown}>
          <Icon icon={GlobeIcon} />
          <span className="dropdown-label">{selectedLanguage}</span>
        </div>
      </div>
    ) : (
      <div className="d-inline-block custom-dropdown">
        <NavMenu
          showResponsiveMobileView={isMobile(window.innerWidth)}
          links={getMenuOptions}
          onClick={() => { }}
          location={window.location.pathname}
          icon={icon}
          keyName="locale_selector">
          <span className="dropdown-label">{selectedLanguage}</span>
        </NavMenu>
      </div>
    )
  }
}

LanguageSelector.propTypes = {
  allowedLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string,
      languageLong: PropTypes.string,
      languageKey: PropTypes.string,
      languageId: PropTypes.number,
    })
  ),
  changeLanguage: PropTypes.func,
  selectedLanguage: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  windowWidth: PropTypes.number,
}

LanguageSelector.defaultProps = {
  allowedLanguages: [],
  changeLanguage: () => { },
  selectedLanguage: {
    language: 'English',
    languageLong: 'English (United States)',
    languageKey: 'EN-US',
    languageId: 1033,
    rtl: false,
  },
  windowWidth: 0,
}

export default LanguageSelector
