import React from 'react'
import PropTypes from 'prop-types'
import './Footer.css'
import { HELPURL } from '../../constants'

const Footer = ({ strings }) => (
  <footer className="footer-root">
    <div className="footer-links">
      <a target="_blank" href="https://documentation.skillsoft.com/en_us/privacy/skillsoft_terms_of_use.htm">
        {strings.termsOfUse}
      </a>
      <a target="_blank" href="https://documentation.skillsoft.com/en_us/privacy/percipio_privacy_policy.htm">
        {strings.privacyPolicy}
      </a>
      <a target="_blank" href={HELPURL}>{strings.help}</a>
    </div>
    <div className="footer-copyright">
      <span aria-hidden="true">©</span>
      {strings.copyright
        .replace(/©/g, '')
        .replace(/{year}/g, new Date().getFullYear())}
    </div>
    <div className="footer-trademarks">{strings.trademarks}</div>
  </footer>
)

Footer.propTypes = {
  strings: PropTypes.objectOf(PropTypes.string),
}

Footer.defaultProps = {
  strings: {},
}

export default Footer
