import { combineReducers } from 'redux'
import { intlReducer } from 'react-intl-redux'

import toastNotification from './common/toastNotification/reducer'
import header from './header/reducer'
import history from './history/reducer'
import modal from './common/modal/reducer'
import root from './root/reducer'
import search from './common/searchBar/reducer'
import player from './player/reducer'
import feedbackModal from './feedbackModal/reducer'

const reducers = combineReducers({
  toastNotification,
  header,
  history,
  intl: intlReducer,
  modal,
  root,
  search,
  player,
  feedbackModal
})

export default reducers
