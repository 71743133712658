import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@skillsoft/ui/lib/Icon'
import SortedDownIcon from '@skillsoft/ui/lib/icons/SortedDown'
import SortedUpIcon from '@skillsoft/ui/lib/icons/SortedUp'
import UnsortedIcon from '@skillsoft/ui/lib/icons/Unsorted'
import classNames from 'classnames'
import { v4 } from 'uuid'
import './Table.css'
import { tableHeadersShape } from '../shapes'

const TableHeaders = ({
  sortFunction,
  announceSortOrder,
  headers,
  sortColumn,
  reverseSort,
}) => (
  <thead>
    <tr>
      {headers.map((th, index) => (
        <th
          key={v4()}
          className={th.sortable ? 'sortable' : ''}
          role="columnheader"
          scope="col"
          aria-sort={announceSortOrder(
            th.sortable,
            reverseSort,
            index,
            sortColumn
          )}
        >
         {th.label && <button
            className={classNames(
              'table-cell-back',
              sortColumn === index ? 'sorted' : ''
            )}
            aria-describedby={
              th.sortable && sortColumn !== index
                ? 'sortableannouncement'
                : null
            }
            // aria-live="polite"
            tabIndex={th.sortable ? '0' : '-1'}
            onKeyPress={e => {
              if (e.key === 'Enter' && th.sortable)
                sortFunction(index, false, true)
            }}
            onClick={() => th.sortable && sortFunction(index, false, true)}
            name={th.label}
            id={index}
          >
            {th.label}
            {th.sortable &&
              (sortColumn === index ? (
                <Icon icon={reverseSort ? SortedDownIcon : SortedUpIcon} />
              ) : (
                <Icon icon={UnsortedIcon} />
              ))}
          </button> }
        </th>
      ))}
    </tr>
  </thead>
)

TableHeaders.propTypes = PropTypes.shape(tableHeadersShape).isRequired

TableHeaders.defaultProps = {
  sortFunction: () => {},
  announceSortOrder: () => {},
  headers: [],
  sortColumn: 0,
  reverseSort: false,
}

export default TableHeaders
