import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import './Table.css'
import { tableBodyShape } from '../shapes'

const TableBodyMobile = ({ sortedData, strings, columnLength }) => (
  <tbody>
    {sortedData.length ? (
      sortedData.map(tr => (
        <tr key={v4()}>
          <td colSpan={columnLength}>
            <div className="mobile-history-type">{tr[1].content}</div>
            <div className="table-cell-back">{tr[0].content}</div>
            <div className="mobile-history-completed">
              {tr[2].sortKey.length ? (
                <span>{`${strings.completed}: ${tr[2].content}`}</span>
              ) : (
                <span>{strings.completed}</span>
              )}
            </div>
            <div className="mobile-history-certificate">{tr[3].content}</div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={columnLength}>
          <div className="table-no-results">{strings.noResultsFound}</div>
        </td>
      </tr>
    )}
  </tbody>
)

TableBodyMobile.propTypes = PropTypes.shape(tableBodyShape).isRequired

TableBodyMobile.defaultProps = {
  sortedData: [],
  strings: {},
  columnLength: 0,
}
export default TableBodyMobile
