import { connect } from 'react-redux'
import LanguageSelector from '../components/LanguageSelector'
import { fetchRootData, fetchStringTranslations } from '../../root/actions'
import * as actions from '../actions'

const mapStateToProps = state => ({
  selectedLanguage: state.root.selectedLanguage,
  allowedLanguages: state.header.allowedLanguages,
  windowWidth: state.root.windowWidth,
  location: state.header.location,
})

const mapDispatchToProps = dispatch => ({
  changeLanguage: async newLanguage => {
    const rootData = await fetchRootData(newLanguage.languageId)
    const strings = await fetchStringTranslations(
      newLanguage.skillportLanguageId
    )
    dispatch(rootData)
    dispatch(strings)
    return dispatch(actions.changeLanguage())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelector)
