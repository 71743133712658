import React from 'react'
import PropTypes from 'prop-types'
import './CourseInfo.css'

const CourseInfo = ({ courseInfo, strings }) => (
  <div className="course-info-wrap">
    <div className="course-info-section">
      <b>{strings.overview}</b>
      {courseInfo.longDescription ? (
        <div dangerouslySetInnerHTML={{ __html: courseInfo.longDescription }} />
      ) : (
        <div>{strings.none}</div>
      )}
    </div>
    {courseInfo.skillCourseNo && (
      <div className="course-info-section">
        <b>{strings.courseNumber}</b>
        <div>{courseInfo.skillCourseNo}</div>
      </div>
    )}
    {courseInfo.intendedAudience && (
      <div className="course-info-section">
        <b>{strings.targetAudience}</b>
        <div>{courseInfo.intendedAudience}</div>
      </div>
    )}
    {courseInfo.prerequisites && (
      <div className="course-info-section">
        <b>{strings.prerequisites}</b>
        <div>{courseInfo.prerequisites}</div>
      </div>
    )}
    {courseInfo.duration && (
      <div className="course-info-section">
        <b>{strings.expectedDuration}</b>
        <div>{formatDuration(courseInfo.duration)}</div>
      </div>
    )}
    {courseInfo.objective && (
      <div className="course-info-section">
        <b>{strings.lessonObjectives}</b>
        <div dangerouslySetInnerHTML={{ __html: courseInfo.objective }} />
      </div>
    )}
    {courseInfo.clientDrivers && (
      <div className="course-info-section">
        <b>{strings.clientDrivers}</b>
        <div dangerouslySetInnerHTML={{ __html: courseInfo.clientDrivers }} />
      </div>
    )}
  </div>
)

const formatDuration = (duration) => {
  const decimalTimeString = duration
  let decimalTime = parseFloat(decimalTimeString)
  decimalTime = decimalTime * 60 * 60
  let hours = Math.floor((decimalTime / (60 * 60)))
  decimalTime = decimalTime - (hours * 60 * 60)
  let minutes = Math.floor((decimalTime / 60))
  decimalTime = decimalTime - (minutes * 60)
  let seconds = Math.round(decimalTime)

  if (hours < 10) hours = "0" + hours

  if (minutes < 10) minutes = "0" + minutes

  if (seconds < 10) seconds = "0" + seconds

  return "" + hours + ":" + minutes + ":" + seconds
}

CourseInfo.propTypes = {
  courseInfo: PropTypes.shape({
    clientDrivers: PropTypes.string,
    courseId: PropTypes.number,
    duration: PropTypes.string,
    intendedAudience: PropTypes.string,
    longDescription: PropTypes.string,
    name: PropTypes.string,
    objective: PropTypes.string,
    primaryDrivers: PropTypes.string,
    relatedDrivers: PropTypes.string,
  }),
  strings: PropTypes.objectOf(PropTypes.string),
}

CourseInfo.defaultProps = {
  courseInfo: {},
  strings: {},
}

export default CourseInfo
