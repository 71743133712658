import * as api from './api'
import persistence from '../utils/persistence'
import { AUTHENTICATIONFAILUREURL } from '../constants'

// Clear local storage and redirect to unauthorized URL.
export const handleAuthenticationFailure = () => {
  persistence.delete('user')
  window.location.href = AUTHENTICATIONFAILUREURL
}

// Creates user object and fixes history object.
export const readyUi = user => {
  persistence.set('user', JSON.stringify(user))
  window.history.replaceState(
    {},
    'Percipio Compliance Learner',
    `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }`
  )
}

// Handles authentication logic.
export const getAuthentication = async (
  token,
  user,
  readyUiFn = readyUi,
  handleAuthenticationFailureFn = handleAuthenticationFailure,
  authenticateUserFn = api.authenticateUser
) => {
  // If a token is passed, check if its valid.
  if (token) {
    const response = await authenticateUserFn(token)
    // If its valid, ready the UI.
    if (response.authorized) {
      readyUiFn(response)
      return response
    }
    return handleAuthenticationFailureFn()
  }
  // If no token, check if user object already exists (e.g., reload, back nav, etc.)
  if (user) return JSON.parse(user)

  // If no token or user, authentication fails.
  return handleAuthenticationFailureFn()
}

export const stringReplace = (string = '', replacementArray = []) => {
  let replacedString = string

  for (let i = 0; i < replacementArray.length; i += 1) {
    if (replacementArray[i].value) {
      const key = new RegExp(`{${replacementArray[i].key}}`, 'g')
      replacedString = replacedString.replace(key, replacementArray[i].value)
    }
  }

  return replacedString
}
