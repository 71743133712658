import { connect } from 'react-redux'
import Header from '../components/Header'

const mapStateToProps = state => ({
  strings: Object.assign(
    {},
    state.intl.messages.header,
    state.intl.messages.global
  ),
  user: state.header.user,
  selectedLanguage: state.root.selectedLanguage,
})

export default connect(mapStateToProps)(Header)
