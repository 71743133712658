import { connect } from 'react-redux'
import History from '../components/History'
import { formatDataForTable } from '../utils'

const mapStateToProps = state => ({
  strings: state.intl.messages.history,
  assignments: state.history.assignments,
  searchedAssignments: formatDataForTable(
    state.history.searchedAssignments,
    {
      ...state.intl.messages.history,
      ...state.intl.messages.assignmentTypes,
    },
    state.root.windowWidth
  ),
})

export default connect(mapStateToProps)(History)
