import { connect } from 'react-redux'
import SearchBar from '../components/SearchBar'
import * as actions from '../actions'

const mapStateToProps = state => ({
  strings: state.intl.messages.searchBar,
  searchCleared: state.search.searchCleared,
})

const mapDispatchToProps = dispatch => ({
  search: (term, searchField, searchData, dispatchType) =>
    dispatch(actions.search(term, searchField, searchData, dispatchType)),
  clearSearch: () => dispatch(actions.clearSearch()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar)
