import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@skillsoft/ui/lib/Icon'
import ToolTip from '@skillsoft/ui/lib/Tooltip'
import FilterListIcon from '@skillsoft/ui/lib/icons/FilterList'
import SearchIcon from '@skillsoft/ui/lib/icons/Search'
import classNames from 'classnames'
import './TableTitle.css'
import { tableTitleShape } from '../shapes'
import { getMobileFilterList } from '../selectors'
import SearchBar from '../../searchBar/containers/SearchBar'
import Dropdown from '../../dropdown/containers/Dropdown'

const TableTitle = ({
  title,
  searchData,
  uiSize,
  mobileSearch,
  toggleMobileSearch,
  sortFunction,
  headers,
  sortColumn,
  reverseSort,
  strings,
}) => (
  <div className={uiSize === 'mobile' ? 'table-title-mobile' : 'table-title'}>
    <div className="table-title-left">
      <h2>{title}</h2>
    </div>
    {uiSize === 'mobile' && (
      <div className="table-title-icons">
        <ToolTip
          overlay={<span>{strings.sortTable || "Sort Compliance history table"}</span>}
          tooltipProps={{
            align: { offset: [0, -8] },
            placement: 'top',
          }}
        >
          <Dropdown
            options={getMobileFilterList(
              headers,
              sortFunction,
              sortColumn,
              reverseSort
            )}
            label={strings.sortTable || "Sort Compliance history table"}
            type="header"
            icon={FilterListIcon}
          />
        </ToolTip>
        <ToolTip
          overlay={<span>{mobileSearch? (strings.hideSearchField || "Hide search field"): (strings.showSearchField || "Show search field")}</span>}
          tooltipProps={{
            align: { offset: [0, -8] },
            placement: 'top',
          }}
        >
          <button
            className={
              mobileSearch
                ? 'table-title-mobile-search-open'
                : 'table-title-mobile-search-closed'
            }
            aria-label={mobileSearch? (strings.hideSearchField || "Hide search field") : (strings.showSearchField || "Show search field")}
            onClick={toggleMobileSearch}
            style={{
              lineHeight: '31px',
            }}
          >
            <Icon icon={SearchIcon} />
          </button>
        </ToolTip>
      </div>
    )}
    <div
      className={classNames({
        'table-title-right': true,
        right: true,
        'table-title-mobile-show': mobileSearch && uiSize === 'mobile',
        'table-title-mobile-hide': !mobileSearch && uiSize === 'mobile',
      })}
    >
      <SearchBar
        searchData={searchData}
        searchField="course"
        dispatchType="SEARCH_HISTORY"
      />
    </div>
  </div>
)

TableTitle.propTypes = PropTypes.shape(tableTitleShape).isRequired

TableTitle.defaultProps = {
  strings: null,
  searchData: [],
}

export default TableTitle
