import { ACADEMY, COURSELISTINFOFPAGE } from '../../constants'

export default (state = {}, { type, payload }) => {
  switch (type) {
    case 'DISMISS_TOAST':
      return {
        ...state,
        displayToast: false,
      }
    case 'COURSE_UPDATE':
      return {
        ...state,
        toastMessage: 'courseUpdateNotification',
        toastActionText: 'viewListOfCourseUpdates',
        to: `${ACADEMY}/${COURSELISTINFOFPAGE}?LangugeID=${
          payload.language.languageId
        }`,
        toTarget: '_blank',
        toastType: 'info',
        displayToast: true,
      }
    case 'ERROR':
      return {
        ...state,
        toastMessage: 'anErrorHasOccurred',
        toastActionText: null,
        to: null,
        toTarget: null,
        toastType: 'warning',
        displayToast: true,
      }
    default:
      return state
  }
}
