import React from 'react'
import ModalDialog from '@skillsoft/ui/lib/ModalDialog'
import style from './FeedbackModal.module.css'
import Button from '@skillsoft/ui/lib/Button'

export default function FeedbackModal(props) {
    const { intl, isOpen, launchUrl } = props;

    const onClose = () => {
        props.onClose();
    }

    const renderError = () => (
        <h1>Error</h1>
    )

    const renderIframe = () => (
        <iframe
            src={launchUrl}
            width="100%"
            height="680"
            frameBorder="0"
            tabIndex={0}
        />
    )

    const renderUrl = () => launchUrl ? renderIframe() : renderError();

    const setFocus = () => {
        document.getElementsByTagName("iframe")[1].focus();
    }

    return (
            <ModalDialog
                tabIndex={1}
                isOpen={isOpen}
                onRequestClose={onClose}
                size="medium"
                onAfterOpen={setFocus}
                            >
                <ModalDialog.Header title={''} showCloseButton={true}/>
                <ModalDialog.Body
                dialogBodyClassName={style.dialogBody}>                    
                    {renderUrl()}
                </ModalDialog.Body>            
            </ModalDialog>
    )
}