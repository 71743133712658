import { connect } from 'react-redux'
import Assignments from '../components/Assignments'
import * as selectors from '../selectors'

const mapStateToProps = state => ({
  strings: state.intl.messages.assignments,
  required: selectors.filterAssignments(state.root.assignments, 'REQ'),
  optional: selectors.filterAssignments(state.root.assignments, 'OPT'),
  windowWidth: state.root.windowWidth,
})

export default connect(mapStateToProps)(Assignments)
