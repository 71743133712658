const filterAssignments = assignments =>
  assignments.filter(
    assignment =>
      assignment.status !== 'NS' &&
      assignment.status !== 'OD' &&
      assignment.status !== 'I'
  )

export default (state = [], { type, payload }) => {
  switch (type) {
    case 'LOAD_ROOT_DATA':
      return {
        ...state,
        assignments: filterAssignments(payload.assignments),
        searchedAssignments: filterAssignments(payload.assignments),
      }
    case 'SEARCH_HISTORY':
      return {
        ...state,
        searchedAssignments: filterAssignments(payload.searchResults),
      }
    default:
      return state
  }
}
