import PropTypes from 'prop-types'

export const launchLinkShape = {
  launchCourse: PropTypes.func,
  confirmCourse: PropTypes.func,
  children: PropTypes.node,
  launchable: PropTypes.bool,
  windowOptions: PropTypes.string,
  courseId: PropTypes.string,
  to: PropTypes.string,
  contentType: PropTypes.string,
  a11yEnabled: PropTypes.bool,
  confirmCompletion: PropTypes.number,
  historical: PropTypes.bool,
  priorCourseTitle: PropTypes.string,
  strings: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  selectedLanguage: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  player: PropTypes.string
}
