// Announces how a column is sorted for a11y.
export const announceSortOrder = (sortable, reverse, index, sortColumn) => {
  let ariaSort = 'none'
  if (sortable && sortColumn === index && reverse) {
    ariaSort = 'descending'
  } else if (sortable && sortColumn === index && !reverse) {
    ariaSort = 'ascending'
  } else {
    ariaSort = 'none'
  }
  return ariaSort
}

// Sorts data in forward or reverse order based on a data item's sortKey.
export const primarySort = (a, b, reverse = false) => {
  const x = a.sortKey.toLowerCase()
  const y = b.sortKey.toLowerCase()
  if (reverse) {
    if (x < y) return 1
    if (x > y) return -1
  }
  if (x < y) return -1
  if (x > y) return 1
  return 0
}

// Sorts data by secondary column in the instance that the clicked column
// contains duplicate data (e.g., two rows have the same date)
export const subSort = (a, b, priority) => {
  for (let i = 0; i < priority.length; i += 1) {
    if (a[priority[i]].sortKey > b[priority[i]].sortKey) return 1
    if (a[priority[i]].sortKey < b[priority[i]].sortKey) return -1
  }
  return 0
}

// Sorts data.
export const sortData = (
  data,
  priority,
  reverse,
  primarySortFn = primarySort,
  subSortFn = subSort
) =>
  data.sort((a, b) => {
    if (a[priority[0]].sortKey !== b[priority[0]].sortKey) {
      return primarySortFn(a[priority[0]], b[priority[0]], reverse)
    }
    return subSortFn(a, b, priority)
  })

// Organizes the columns to sort by.
export const getSortPriority = (columnIndex, sortPriority) => {
  const priority = [columnIndex]
  for (let i = 0; i < sortPriority.length; i += 1) {
    if (columnIndex !== sortPriority[i]) priority.push(sortPriority[i])
  }
  return priority
}

// Reverses sort order if currently sorted column is clicked
export const reverseSortedData = (columnIndex, sortColumn, prevReverse) => {
  let reverse = true
  if (columnIndex === sortColumn) {
    reverse = !prevReverse
  }
  return reverse
}
