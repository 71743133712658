import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl-redux'
import cssVars from 'css-vars-ponyfill'
import 'url-polyfill'
import Root from './root/containers/Root'
import configureStore from './configureStore'

cssVars()
const store = configureStore()

render(
  <Provider store={store}>
    <IntlProvider locale="en">
      <Root />
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
)
