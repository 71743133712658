import isMobile from './isMobile'

// Check if course can be launched.
export const isLaunchable = (
  launch,
  launchInReview,
  ) => {
  if (!launch && !launchInReview) return false
  return true
}


export const checkMobileSettings = (
  launch,
  launchInReview,
  supportedPlatformBitmap,
  windowWidth,
  wbt = true,
  isMobileFn = isMobile
) => {
  if (!launch && !launchInReview) return false
  const checkMobile = isMobileFn(windowWidth)
  if (checkMobile && wbt) {
    if (supportedPlatformBitmap === 13 || supportedPlatformBitmap === 4) {
      return false
    }
  }
  return true
}
