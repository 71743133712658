import { connect } from 'react-redux'
import Footer from '../components/Footer'

const mapStateToProps = state => ({
  strings: Object.assign(
    {},
    state.intl.messages.footer,
    state.intl.messages.global
  ),
})

export default connect(mapStateToProps)(Footer)
