import { connect } from 'react-redux'
import Player from '../components/Player'
import * as actions from '../actions'
import feedbackModalActions from '../../feedbackModal/actions'
import * as api from '../api'


const mapStateToProps = state => ({
    a11yEnabled: state.player.a11yEnabled,
    courseUrl: state.player.courseUrl,
    strings: state.intl.messages.a11yToggle,
    feedbackModalIsOpen: state.feedbackModal.isOpen,
    orgId: state.header.user.organizationId,
    disablePercA11yToggle: state.header.user.disablePercA11yToggle,
    studentId: state.header.user.studentId
})

const mapDispatchToProps = dispatch => ({
    launchCourse: async (launchParams, launchUrl) => {
        if (!launchParams.launchable || !launchParams.checkMobileSettings) return false
        const course = await actions.launchCourse(launchParams, launchUrl)
        return dispatch(course)
    },
    toggleA11y: toggle => dispatch(actions.toggleA11y(toggle)),
    giveFeedback: async (launchParams, orgId, studentId) => {
        const { percipioLanguageID, courseId, complianceSurveyOrgID, complianceSurveyMainProductID } = launchParams
        const response = await api.fetchCertitudeLaunchURL(complianceSurveyOrgID, complianceSurveyMainProductID, percipioLanguageID, orgId, studentId, courseId)
        return dispatch(feedbackModalActions.openModal(response.certitudeCourseEvalUrl))
    },
    NASBAFeedback: async (launchParams, orgId, studentId) => {
        const { percipioLanguageID, courseId, nasbaSurveyOrgID, nasbaSurveyMainProductID } = launchParams
        const response = await api.fetchCertitudeLaunchURL(nasbaSurveyOrgID, nasbaSurveyMainProductID, percipioLanguageID, orgId, studentId, courseId)
        return dispatch(feedbackModalActions.openModal(response.certitudeCourseEvalUrl))
    },

})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Player)
