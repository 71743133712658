import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../common/table/containers/Table'
import style from './History.module.css'

const History = ({ assignments, searchedAssignments, strings }) =>
  assignments.length ? (
    <div className={style.wrapper}>
      <div className={style.history}>
        <Table
          headers={[
            {
              label: strings.content,
              sortable: true,
            },
            {
              label: strings.type,
              sortable: true,
            },
            {
              label: strings.completed,
              sortable: true,
            },
            {
              label: '',
              sortable: false,
            },
          ]}
          data={searchedAssignments}
          defaultSort={2}
          sortPriority={[0, 2]}
          title={strings.complianceHistory}
          defaultData={assignments}
        />
      </div>
    </div>
  ) : null

History.propTypes = {
  assignments: PropTypes.arrayOf(PropTypes.object),
  searchedAssignments: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        sortKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      })
    )
  ),
  strings: PropTypes.objectOf(PropTypes.string),
}

History.defaultProps = {
  assignments: [],
  searchedAssignments: [],
  strings: null,
}

export default History
