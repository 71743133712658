import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '@skillsoft/ui/lib/Icon'
import CollapseIcon from '@skillsoft/ui/lib/icons/Collapse'
import ExpandIcon from '@skillsoft/ui/lib/icons/Expand'
import Paper from '@skillsoft/ui/lib/Paper'
import './Dropdown.css'
import { KEYS } from '../../../constants'

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: true,
      open: false,
      selected: false,
      focusIndex: null,
    }
    this.menuItems = []
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.closeDropdown = this.closeDropdown.bind(this)
    this.previousDropdown = this.previousDropdown.bind(this)
    this.nextDropdown = this.nextDropdown.bind(this)
    this.tabDropdown = this.tabDropdown.bind(this)
    this.componentDidUpdate = this.componentDidUpdate.bind(this)
  }

  componentDidUpdate() {
    if (this.state.open) {
      this.focusDropdown()
    }
  }

  previousDropdown() {
    if (!this.state.open) return false
    if (this.state.open && this.state.focusIndex > 0)
      return this.setState(prevState => ({
        focusIndex: prevState.focusIndex - 1,
      }))
    return this.setState(prevState => ({
      focusIndex: prevState.focusIndex + this.props.options.length - 1,
    }))
  }

  nextDropdown(eventType) {
    // if dropdown is collapsed, expand it first, then move to first index
    if (!this.state.open) return this.toggleDropdown(eventType)
    if (this.state.focusIndex >= this.props.options.length - 1)
      return this.setState({ focusIndex: 0 })
    return this.setState(prevState => ({
      focusIndex: prevState.focusIndex + 1,
    }))
  }

  focusDropdown() {
    if (!this.state.open) return

    const focusedElement = document.getElementsByClassName(
      'dropdown-menu-focused'
    )[0]
    if (focusedElement !== undefined) {
      const dropdown = focusedElement.parentElement.parentElement
      dropdown.scrollTop =
        focusedElement.offsetTop -
        parseInt(
          window
            .getComputedStyle(dropdown, null)
            .getPropertyValue('padding-top'),
          10
        )
    }
  }

  tabDropdown(eventType) {
    if (this.state.open) this.toggleDropdown(eventType)
  }

  toggleDropdown(eventType) {
    this.setState(prevState => ({
      open: !prevState.open,
      initial: false,
      disableOnClickOutside: !prevState.disableOnClickOutside,
      focusIndex: prevState.open || eventType === 'click' ? null : 0,
    }))
  }

  closeDropdown(eventType) {
    this.setState(prevState => ({
      open: false,
      initial: false,
      disableOnClickOutside: true,
      focusIndex: prevState.open || eventType === 'click' ? null : 0,
    }))
  }

  handleClickOutside() {
    this.setState({
      open: false,
      disableOnClickOutside: true,
      focusIndex: null,
    })
  }

  handleClick(callback) {
    this.handleClickOutside()
    if (callback) callback()
  }

  handleKeyUp(event) {
    switch (event.which) {
      case KEYS.SPACE:
        event.preventDefault()
        event.stopPropagation()
        break
      default:
    }
  }

  handleKeyDown(event) {
    switch (event.which) {
      case KEYS.RETURN:
      case KEYS.SPACE:
        event.preventDefault()
        if (!this.state.open) {
          this.toggleDropdown(event.type)
        } else {
          this.handleClick(this.menuItems[this.state.focusIndex].click())
        }
        break
      case KEYS.UP:
        event.preventDefault()
        this.previousDropdown()
        break
      case KEYS.DOWN:
        event.preventDefault()
        this.nextDropdown(event.type)
        break
      case KEYS.TAB:
        this.tabDropdown(event.type)
        break
      case KEYS.ESC:
        event.preventDefault()
        this.closeDropdown(event.type)
        break
      default:
    }
  }

  render() {
    const { icon, label, options, type } = this.props
    return (
      <div
        className={classNames({
          [`dropdown-${type}`]: true,
          active: this.state.open,
        })}
      >
        <button
          aria-label={label}
          aria-expanded={this.state.open}
          aria-haspopup="true"
          onClick={e => this.toggleDropdown(e.type)}
          onKeyDown={e => this.handleKeyDown(e)}
          onKeyUp={e => this.handleKeyUp(e)}
        >
          <span className="dropdown-icon">
            <Icon icon={icon} />
          </span>
          <span className="dropdown-label">{label}</span>
          <span className="dropdown-expand">
            {this.state.open ? (
              <Icon icon={CollapseIcon} />
            ) : (
              <Icon icon={ExpandIcon} />
            )}
          </span>
        </button>
        <div
          className={classNames({
            'dropdown-menu': true,
            expanded: this.state.open,
            condensed: !this.state.open && !this.state.initial,
          })}
        >
          <Paper>
            <ul
              role="menu"
              style={{
                maxHeight: window.innerHeight * 0.5,
              }}
            >
              {options.map((option, index) => (
                <li key={option.id} role="presentation">
                  <a
                    ref={menuitem => {
                      this.menuItems[index] = menuitem
                    }}
                    className={
                      this.state.focusIndex === index && this.state.open
                        ? 'dropdown-menu-focused'
                        : ''
                    }
                    role="menuitem"
                    tabIndex="-1"
                    href={option.link}
                    target={option.newWindow ? '_blank' : '_self'}
                    onClick={() => this.handleClick(option.onClick)}
                  >
                    {option.content}
                  </a>
                </li>
              ))}
            </ul>
          </Paper>
        </div>
      </div>
    )
  }
}

Dropdown.propTypes = {
  icon: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      click: PropTypes.func,
    })
  ),
  type: PropTypes.string,
}

Dropdown.defaultProps = {
  icon: null,
  label: null,
  options: [],
  type: null,
}

export default Dropdown
