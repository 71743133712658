import PropTypes from 'prop-types'

export const rootShape = {
  loading: PropTypes.bool,
  authenticateUser: PropTypes.func,
  fetchStringTranslations: PropTypes.func,
  fetchRootData: PropTypes.func,
  fetchConfig: PropTypes.func,
  courseUpdateNotification: PropTypes.func,
  percipioUUID: PropTypes.string,
  assignments: PropTypes.arrayOf(PropTypes.object),
  selectedLanguage: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
}
