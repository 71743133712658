import React from 'react'
import PropTypes from 'prop-types'
import './SkipLink.css'

const skipLink = ({ strings }) => (
  <a href="#mainContent" className="skipLink skipLink-focusable">
    {strings.skipMainContent}
  </a>
)

skipLink.propTypes = {
  strings: PropTypes.objectOf(PropTypes.string),
}

skipLink.defaultProps = {
  strings: {},
}

export default skipLink
