import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import Loading from '@skillsoft/ui/lib/Loading'
import { debounce } from 'throttle-debounce'
import { rootShape } from '../shapes'
import StyleProcessor from '../../styleProcessor/containers/StyleProcessor'
import ToastNotification from '../../common/toastNotification/containers/ToastNotification'
import Header from '../../header/containers/Header'
import Breadcrumb from '../../breadcrumb/containers/Breadcrumb'
import Assignments from '../../assignments/containers/Assignments'
import Player from '../../player/containers/Player'
import History from '../../history/containers/History'
import Footer from '../../footer/containers/Footer'
import Modal from '../../common/modal/containers/Modal'
import SkipLink from '../../skipLink/containers/SkipLink'
import style from './Root.module.css'
import './rtlstyle.css'

class Root extends Component {
  static propTypes = PropTypes.shape(rootShape).isRequired
  static defaultProps = {
    loading: true,
    authenticateUser: () => { },
    fetchStringTranslations: () => { },
    fetchRootData: () => { },
    fetchConfig: () => { },
    courseUpdateNotification: () => { },
    percipioUUID: '',
    assignments: [],
    selectedLanguage: {
      language: 'English',
      languageLong: 'English (United States)',
      languageKey: 'EN-US',
      languageId: 1033,
      rtl: false,
    },
  }

  constructor(props) {
    super(props)
    this.updateWindowDimensions = debounce(
      250,
      this.updateWindowDimensions
    ).bind(this)
  }

  async componentWillMount() {
    const {
      fetchRootData,
      saveReferrerUrl,
      authenticateUser,
      fetchStringTranslations,
      fetchConfig,
    } = this.props
    this.updateWindowDimensions()
    await saveReferrerUrl(document.referrer)
    await fetchConfig()
    const user = await authenticateUser()
    await fetchStringTranslations(user.skillportLanguageId)
    await fetchRootData(parseInt(user.langId, 10))
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentDidMount() {
    
  }

  componentDidUpdate() {
    const {
      assignments,
      courseUpdateNotification,
      selectedLanguage
    } = this.props
    courseUpdateNotification(assignments, selectedLanguage)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    const { screenResize } = this.props
    screenResize(window.innerWidth)
  }

  render() {
    const { loading, selectedLanguage } = this.props
    return (
      <Router>
        <div className={selectedLanguage.rtl ? 'rtl' : ''}>
          <StyleProcessor />
          <ToastNotification />
          <Loading loading={loading} withLogo>
            <div className={style.container}>
              <SkipLink />
              <Header />
              <div id="mainContent" role="main">
              <Breadcrumb />
              <Switch>
                <Route exact path="/player">
                  <Player />
                </Route>
                <Route exact path="/history">
                  <History />
                </Route>
                <Route exact path="/index">
                  <Redirect to="/" />
                </Route>
                <Route exact path="/">
                  <Assignments />
                </Route>
              </Switch>
              <Modal isRTL={selectedLanguage.rtl ? 'rtl' : ''}/>
            </div>
            </div>
            <Footer />
          </Loading>
        </div>
      </Router>
    )
  }
}

export default Root
