import React from 'react'
import CourseInfo from '../../courseInfo/containers/CourseInfo'

export const closeModal = {
  type: 'UPDATE_MODAL',
  payload: { isOpen: false, html: null },
}

export const displayCourseInfo = async (courseInfo, strings, closeModalFn) => ({
  type: 'UPDATE_MODAL',
  payload: {
    isOpen: true,
    title: courseInfo.name,
    html: <CourseInfo courseInfo={courseInfo} />,
    primaryButtonLabel: strings.close,
    secondaryButtonLabel: null,
    primaryButtonClick: closeModalFn,
  },
})
