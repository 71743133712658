import React from 'react'
import { Link } from 'react-router-dom'
import style from './components/Header.module.css'
import { HELPURL } from '../constants'


export const userNameDisplay = (user, strings) => {
  let userNameString = strings.myProfile
  if (user.firstName && user.lastName) {
    userNameString = `${user.firstName} ${user.lastName}`
  } else if (user.lastName) {
    userNameString = user.lastName
  } else if (user.firstName) {
    userNameString = user.firstName
  } else if (user.userId) {
    userNameString = user.userId
  }
  return userNameString
}

export const userOptions = (
  user,
  strings,
  deleteStoredValueFn,
  getStoredValueFn,
  fetchAdminUrlFn
) => {
  const options = []
  if (user.compOnly === false) {
    const referrer = getStoredValueFn('referrer')
    options.push({
      id: 'user-nav-home',
      content: strings.backToLearning,
      onClick: () => deleteStoredValueFn('referrer'),
      link: referrer,
    })
  }
  if (user.isAdmin)
    options.push({
      id: 'user-nav-admin',
      content: strings.administrativeTasks,
      onClick: () => fetchAdminUrlFn(),
      newWindow: false,
    })
  options.push({
    id: 'user-nav-assignments',
    content: <Link to="/" className={style.navAssignments}>{strings.assignments}</Link>,
    ariaLabel: strings.assignments,
    newWindow: false,
  })
  options.push({
    id: 'user-nav-history',
    content: <Link to="/history" className={style.navHistory}>{strings.trainingHistory}</Link>,
    ariaLabel: strings.history,
    newWindow: false,
  })
  options.push({
    id: 'user-nav-help',
    content: strings.help,
    link: HELPURL,
    newWindow: true,
  })
  if (user.percipioReturnUrl) {
    options.push({
      id: 'user-nav-signout',
      content: strings.signOut,
      link: `${user.percipioReturnUrl}/login.html#/logout`,
      newWindow: false,
    })
  }
  return options
}
