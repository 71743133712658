import React, { Component } from 'react'
import { debounce } from 'throttle-debounce'
import PropTypes from 'prop-types'
import Icon from '@skillsoft/ui/lib/Icon'
import SearchIcon from '@skillsoft/ui/lib/icons/Search'
import './SearchBar.css'

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      displayValue: '',
    }

    this.updateInputValue = this.updateInputValue.bind(this)
    this.searchInput = debounce(250, this.searchInput).bind(this)
  }

  componentDidUpdate() {
    this.onUpdate()
  }

  onUpdate() {
    const { searchCleared, clearSearch } = this.props
    if (searchCleared) {
      this.setState({ inputValue: '', displayValue: '' })
      clearSearch()
    }
  }

  updateInputValue(event) {
    return this.setState(
      {
        displayValue: event.target.value,
        inputValue: event.target.value.toLowerCase(),
      },
      this.searchInput()
    )
  }

  searchInput() {
    const { searchData, searchField, search, dispatchType } = this.props
    search(this.state.inputValue, searchField, searchData, dispatchType)
  }

  render() {
    const { strings } = this.props

    return (
      <div className="search-bar-root">
        <input
          type="text"
          aria-label="Search Compliance History"
          className="search-bar"
          placeholder={strings.searchComplianceHistory}
          onChange={this.updateInputValue}
          value={this.state.displayValue}
        />
        <button className="search-icon" onClick={this.searchInput} aria-label="search">
          <Icon icon={SearchIcon} />
        </button>
      </div>
    )
  }
}

SearchBar.propTypes = {
  strings: PropTypes.objectOf(PropTypes.string),
  searchData: PropTypes.arrayOf(PropTypes.object),
  searchField: PropTypes.string,
  search: PropTypes.func,
  dispatchType: PropTypes.string,
  searchCleared: PropTypes.bool,
  clearSearch: PropTypes.func,
}

SearchBar.defaultProps = {
  strings: {},
  searchData: [],
  searchField: null,
  search: () => {},
  dispatchType: null,
  searchCleared: false,
  clearSearch: () => {},
}

export default SearchBar
