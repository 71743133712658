import { ACTIONS } from './actions';

const defaultState = {
    isOpen: false,
    intl: {
        close: "",
        title: ""
    },
    launchUrl: null
}

export default function Reducer(state = defaultState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case ACTIONS.OPEN_FEEDBACK_MODAL:
            return {
                ...state,
                isOpen: true,
                launchUrl: payload.launchUrl
            }
        case ACTIONS.CLOSE_FEEDBACK_MODAL:
            return {
                ...state,
                isOpen: false
            }
        default:
            return state
    }
}