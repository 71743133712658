import validation from '../utils/validation'

const { BFF } = require('../constants.js')

export const authenticateUser = async token => {
  const response = await fetch(`${BFF}directpost`, {
    body: JSON.stringify({ token }),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  })
  if (!validation.validate(response)) return null
  return response.json()
}

export const fetchRootData = async params => {
  const response = await fetch(`${BFF}learner/${params.languageId}`, {
    headers: new Headers({
      Authorization: `Bearer ${params.jwt}`,
    }),
  })
  if (!validation.validate(response)) return null
  return response.json()
}

export const fetchStringTranslations = async (languageCode = 'EN-US') => {
  let code = languageCode
  if (code === '') code = 'EN-US'
  const response = await fetch(
    `${process.env.PUBLIC_URL}/static/lang/${code}.json`
  )
  return response.json()
}

export const fetchConfig = async () => {
  const response = await fetch(`${BFF}learner/config`)
  return response.json()
}
