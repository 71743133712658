import { connect } from 'react-redux'
import LaunchLink from '../components/LaunchLink'
import * as actions from '../actions'
import persistence from '../../../utils/persistence'

const mapStateToProps = state => ({
  strings: {
    ...state.intl.messages.modal,
    ...state.intl.messages.popup,
  },
  config: state.root.config,
  selectedLanguage: state.root.selectedLanguage,
})

const mapDispatchToProps = dispatch => ({
  confirmCourse: async courseId => {
    const confirmCourse = await actions.confirmCourse(courseId)
    return dispatch(confirmCourse)
  },
  launchCourse: async (launchParams, modalParams, courseWindow, player) => {
    const modal = await actions.displayModal(
      modalParams,
      dispatch,
      courseWindow, 
      player
    )
    persistence.set('launchParams', JSON.stringify(launchParams))
    if (courseWindow) {  
      const course = await actions.launchCourse(launchParams)  // calls window.open("", "courseWindow").location.href = url;
      dispatch(modal) // calls function that posts completion
      return dispatch(course)
    }
    return dispatch(modal)
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LaunchLink)
