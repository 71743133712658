import persistence from '../utils/persistence'

function saveUserLanguage(selectedLanguage) {
  const userData = persistence.get('user');
  if (!userData)
    return;

  try {
    const user = JSON.parse(userData)
    persistence.set('user', JSON.stringify({
      ...user,
      userInfo: {
        ...user.userInfo,
        langId: selectedLanguage.languageId,
        skillportLanguageId: selectedLanguage.skillportLanguageId
      }
    }))
  } catch (ex) {
    console.error(ex)
  }

}

export default (state = {}, { type, payload }) => {
  switch (type) {
    case 'LOAD_ROOT_DATA':
      saveUserLanguage(payload.selectedLanguage)

      return {
        ...state,
        loading: false,
        assignments: payload.assignments,
        selectedLanguage: {
          ...payload.selectedLanguage,
        },
      }
    case 'ERROR':
      return {
        ...state,
        loading: false,
      }
    case 'GET_CONFIG':
      return {
        ...state,
        config: payload,
      }
    case 'SCREEN_RESIZE':
      return {
        ...state,
        ...payload,
      }
    case 'SESSION_INVALID':
      window.location = persistence.get('referrer')
      return {
        ...state,
        ...payload,
        loading: true,
      }
    default:
      return state
  }
}
