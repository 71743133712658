export default (state = {}, { type, payload }) => {
  switch (type) {
    case 'AUTHENTICATE_USER':
      return {
        ...state,
        user: payload.userInfo,
      }
    case 'LOAD_ROOT_DATA':
      return {
        ...state,
        allowedLanguages: payload.languages,
        user: {
          ...state.user,
          ...payload.user,
        },
      }
    case 'UPDATE_LOCATION':
      return {
        ...state,
        location: payload,
      }
    default:
      return state
  }
}
