import React from 'react'
import PropTypes from 'prop-types'
import './Logo.css'
import persistence from '../../utils/persistence'
import percipioLogo from '../../common/img/logo.png'

const Logo = ({ user, logoAlt }) => {
  const returnUrl = () => {
    if (user.compOnly === false) return persistence.get('referrer')
    return null
  }

  const addDefaultLogo = ev => {
    const image = ev
    image.target.src = percipioLogo
  }

  return (
    <div className="logo">
      <a
        onClick={e => {
          if (returnUrl()) {
            e.preventDefault()
            const referrerUrl = returnUrl()
            persistence.delete('referrer')
            window.location.href = referrerUrl
          }
        }}
        href={returnUrl()}
      >
        {
          <img
            onError={addDefaultLogo}
            alt={logoAlt}
            src={user.logoSrc || percipioLogo}
          />
        }
      </a>
    </div>
  )
}
Logo.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  logoAlt: PropTypes.string,
}

Logo.defaultProps = {
  user: {},
  logoAlt: null,
}

export default Logo
