import { connect } from 'react-redux'
import Breadcrumb from '../components/Breadcrumb'
import * as actions from '../actions'

const mapStateToProps = state => ({
    user: state.header.user,
    strings: state.intl.messages.breadcrumb,
})

const mapDispatchToProps = dispatch => ({
    updateLocation: location => dispatch(actions.updateLocation(location))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Breadcrumb)
