import React from 'react'
import PropTypes from 'prop-types'

const StyleProcessor = ({ styles }) => <style>{styles}</style>

StyleProcessor.propTypes = {
  styles: PropTypes.string,
}

StyleProcessor.defaultProps = {
  styles: null,
}

export default StyleProcessor
