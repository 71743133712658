import PropTypes from 'prop-types'

export const cardShape = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  overdue: PropTypes.string,
  strings: PropTypes.objectOf(PropTypes.string),
  windowWidth: PropTypes.number,
  tabbable: PropTypes.bool,
}
