import * as api from "./api";
import { fetchRootData } from "../../root/actions";
import { stringReplace } from "../../root/utils";

export const closeModal = () => ({
  type: "UPDATE_MODAL",
  payload: { isOpen: false },
});

export const confirmCourse = async (
  courseId,
  confirmCourseFn = api.confirmCourse
) => {
  try {
    const confirmCourseResponse = await confirmCourseFn(courseId);
    if (confirmCourseResponse === null) {
      return { type: "SESSION_INVALID" };
    }
    return {
      type: "COURSE_CONFIRMED",
      payload: { courseId },
    };
  } catch (err) {
    return {
      type: "ERROR",
      payload: err,
    };
  }
};

export const displayModal = async (modalParams, dispatch, courseWindow, player) => {
  const {
    confirmCompletion,
    contentType,
    courseId,
    launchable,
    isMobile,
    checkMobileSettings,
    priorCourseTitle,
    selectedLanguage,
    strings,
    title,
  } = modalParams;
  if (!launchable && priorCourseTitle !== null && priorCourseTitle !== "") {
    // course has a prerequisite that must be completed first
    return {
      type: "UPDATE_MODAL",
      payload: {
        isOpen: true,
        title: strings.prerequisiteCourseTitle,
        content: stringReplace(strings.prerequisiteCourse, [
          { key: "firstCourseName", value: title },
          { key: "secondCourseName", value: priorCourseTitle },
        ]),
        primaryButtonLabel: strings.cancel,
        secondaryButtonLabel: null,
        primaryButtonClick: () => {
          dispatch(closeModal());
        },
      },
    };
  }

  if (!launchable) {
    // course is not launchable
    return {
      type: "UPDATE_MODAL",
      payload: {
        isOpen: true,
        title: strings.cannotLaunchCourse,
        content: strings.courseNotLaunchable,
        primaryButtonLabel: strings.cancel,
        secondaryButtonLabel: null,
        primaryButtonClick: () => {
          dispatch(closeModal());
        },
      },
    };
  }

  const isMobileLaunchable = isMobile ? checkMobileSettings : launchable;

  if (!isMobileLaunchable) {
    // not launchable on mobile
    return {
      type: "UPDATE_MODAL",
      payload: {
        isOpen: true,
        title: strings.cannotLaunchCourse,
        content: strings.courseNotLaunchableOnDevice,
        primaryButtonLabel: strings.cancel,
        secondaryButtonLabel: null,
        primaryButtonClick: () => {
          dispatch(closeModal());
        },
      },
    };
  }

  if (contentType === "LNK") {
    if (confirmCompletion === 1) {
      // display modal to prompt for confirmation
      return {
        type: "UPDATE_MODAL",
        payload: {
          isOpen: true,
          title: strings.completionConfirmation,
          content: strings.completionConfirmationDescription,
          primaryButtonLabel: strings.confirm,
          secondaryButtonLabel: strings.cancel,
          primaryButtonClick: async () => {
            dispatch(await confirmCourse(courseId));
            dispatch(await fetchRootData(selectedLanguage.languageId));
            dispatch(closeModal());
          },
          secondaryButtonClick: () => {
            dispatch(closeModal());
          },
        },
      };
    } else if (confirmCompletion === 2) {
      // confirm automatically, do not display modal
      dispatch(await confirmCourse(courseId));
      dispatch(await fetchRootData(selectedLanguage.languageId));
    }
    else if (player === "other") {
      return {
        type: "UPDATE_MODAL",
        payload: {
          isOpen: true,
          title: strings.courseInProgress,
          content: strings.courseInWindow,
          primaryButtonLabel: strings.close,
          secondaryButtonLabel: null,
          primaryButtonClick: async () => {
            dispatch(await fetchRootData(selectedLanguage.languageId));
            const newWindow = courseWindow;
            newWindow.close();
            return dispatch(closeModal());
          },
        },
      };
    } 
  } else { // NOT  contentType === "LNK"
    if (player === "other") {
      return {
        type: "UPDATE_MODAL",
        payload: {
          isOpen: true,
          title: strings.courseInProgress,
          content: strings.courseInWindow,
          primaryButtonLabel: strings.close,
          secondaryButtonLabel: null,
          primaryButtonClick: async () => {
            dispatch(await fetchRootData(selectedLanguage.languageId));
            const newWindow = courseWindow;
            newWindow.close();
            return dispatch(closeModal());
          },
        },
      };
    } else {
      return { type: "NO_MODAL" };
    } 
	}
  return { type: "NO_MODAL" };
};
export const launchCourse = async (
  launchParams,
  getlaunchUrlFn = api.getLaunchUrl
) => {
  const { courseId } = launchParams;
  try {
    const urlParams = {
      to: launchParams.to,
      contentType: launchParams.contentType,
      courseId: launchParams.courseId,
      a11yEnabled: launchParams.a11yEnabled,
    };
    const url = await getlaunchUrlFn(urlParams);
    if (url === null) {
      return { type: "SESSION_INVALID" };
    }
    window.open("", "courseWindow").location.href = url;
    return {
      type: "COURSE_LAUNCHED",
      payload: { courseId },
    };
  } catch (err) {
    return {
      type: "ERROR",
      payload: err,
    };
  }
};
