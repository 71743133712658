import React from 'react'
import PropTypes from 'prop-types'
import Logo from './Logo'
import persistence from '../../utils/persistence'
import LanguageSelector from '../containers/LanguageSelector'
import UserNav from '../containers/UserNav'
import style from './Header.module.css'

const Header = ({ strings, user, customStyles, }) => (
  <header
    className={style.header}
    style={{
      backgroundColor: customStyles.headerBackgroundColor,
    }}
  >
    <div className={style.headerContent}>
      <div className={style.left}>
        <h1>
          <Logo
            returnUrl={persistence.get('referrer')}
            user={user}
            logoAlt={strings.logoAlt}
          />
        </h1>
      </div>
      <div className={style.right}>
        <LanguageSelector />
        <UserNav />
      </div>

    </div>
  </header>
)

Header.propTypes = {
  strings: PropTypes.objectOf(PropTypes.string),
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
}

Header.defaultProps = {
  strings: {},
  user: {},
  customStyles: {
    headerBackgroundColor: '#fff',
  },
}

export default Header
